import React from "react";
import { config as DropConfig } from "./config";
import Select from "react-dropdown-select";

export const DropDownConfig = ({
  filter,
  selectValues,
  setValues,
  dropdownbox,
  options,
}) => {
  if (dropdownbox && dropdownbox.height > 0 && options) {
    const config = DropConfig(filter);

    const tstyle = {
      minWidth: `${config.minWidth}px`,
      maxWidth: `${config.maxWidth}px`,
    };

    const parentstyle = {
      display: "flex",
      justifyContent: "start",
      fontSize: config.text.fontSize,
      fontWeight: config.text.fontWeight,
      color: config.text.color,
    };

    const contentStyle = {
      backgroundColor: config.color,
      paddingRight: 5,
      paddingLeft: 5,
      borderRadius: 2,
      marginTop: 3,
      marginLeft: 5,
      marginRight: 0,
      marginBottom: 3,
      color: "#ffffff",
      display: "flex",
      flexDirection: "row",
      boxSizing: "border-box",
      lineHeight: "21px",
    };

    const contentText = { cursor: "pointer", lineHeight: "21px" };

    const contentX = {
      color: "#ffffff",
      width: "22px",
      height: "22px",
      cursor: "pointer",
      textAlign: "center",
      marginRight: -5,
      marginLeft: 0,
      marginTop: 0,
      marginBottom: 0,
      display: "inline-block",
      borderTopRightRadius: 3,
      borderBottomRightRadius: 3,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      fontSize:"11px"

    };
    const clearX = {
      lineHeight: "25px",
      cursor: "pointer",
      marginRight: 10,
      marginLeft: 10,
      display: "block",
      boxSizing: "border-box",
      fontSize:"11px"
    };
    const placeholderStyle = { fontSize: "smaller", color: "#757575" };

    const itemRenderer = ({ item, itemIndex, props, state, methods }) => {
      const addItem = (pitem) => {
        if (pitem.indented) {
          if (
            selectValues &&
            selectValues.map((item) => item.id).includes(pitem.id)
          ) {
            const newSelection = selectValues.filter((item) => {
              return item.id !== pitem.id;
            });
            setValues(newSelection);
          } else {
            const addedSelection = selectValues ? [...selectValues] : [];
            addedSelection.push(pitem);
            setValues(addedSelection);
          }
          methods.addItem(pitem);
        } else {
          const currentSelection = selectValues ? [...selectValues] : [];
          const newadd = props.options.filter(
            (fitem) => fitem.parentId === pitem.id
          );
          const oValues = currentSelection.reduce((a, b) => {
            return { ...a, [b.id]: b };
          }, {});
          const finalValues = newadd.reduce((a, b) => {
            return { ...a, [b.id]: b };
          }, oValues);
          const finalArray = Object.values(finalValues).filter(
            (item) => item.indented
          );
          setValues(finalArray);
          newadd.forEach((item) => {
            if (!props.values.map((p) => p.id).includes(item.id)) {
              methods.addItem(item);
            }
          });
        }
      };

      const selected = selectValues
        ? selectValues.map((item) => item.id).includes(item.id)
        : false;
      const itemStyle = {
        marginLeft: item.indented ? `${config.indent}px` : "0px",
        marginRight: `${config.items.margin.right}px`,
        paddingTop: `${config.items.padding.top}px`,
        paddingBottom: `${config.items.padding.bottom}px`,
        paddingLeft: `${config.items.padding.left}px`,
      };

      const selectedStyle = {
        ...itemStyle,
        ...{
          backgroundColor: config.color,
          color: "#FFFFFF",
        },
      };

      const disabledStyle = {
        marginLeft: `${config.headers.margin.left}px`,
        marginRight: `${config.headers.margin.right}px`,
        backgroundColor: config.headers.backgroundColor,
        color: config.headers.color,
        paddingTop: `${config.headers.padding.top}px`,
        paddingBottom: `${config.headers.padding.bottom}px`,
        paddingLeft: `${config.headers.padding.left}px`,
      };

      return (
        <div
          key={item[props.valueField]}
          onClick={() => (item.disabled ? null : addItem(item))}
        >
          <div
            style={
              selected
                ? selectedStyle
                : !item.indented
                ? disabledStyle
                : itemStyle
            }
          >
            {item[props.labelField]}
          </div>
        </div>
      );
    };

    const clearRenderer = ({ props, state, methods }) => {
      return (
        <div style={clearX} onClick={() => onClearAll()}>
          x
        </div>
      );
    };
    const onSelectAll = () => {
      const AllValues = options.filter((item) => {
        return item.indented;
      });
      setValues(AllValues);
    };
    const onClearAll = () => {
      setValues();
    };

    const removeItem = (item) => {
      const newSelection = selectValues.filter((fitem) => {
        return fitem.id !== item.id;
      });
      setValues(newSelection);
    };

    const contentRenderer = ({ props, state, methods }) => {
      return selectValues && selectValues.length > 0 ? (
        <>
          {selectValues.map((item, i) => {
            return (
              <span key={i} style={contentStyle}>
                <span style={contentText}>{item.name}</span>
                <span style={contentX} onClick={() => removeItem(item)}>
                  x
                </span>
              </span>
            );
          })}
        </>
      ) : (
        <span style={placeholderStyle}>{props.placeholder}</span>
      );
    };

    const dropdownHeight = dropdownbox.height - 45;

    return (
      <div style={parentstyle}>
        <Select
          options={options}
          // onChange={(values) => makeselection(values)}
          placeholder={config.placeholder}
          addPlaceholder={config.addPlaceholder}
          color={config.color}
          disabled={config.disabled}
          disabledLabel={config.disabledLabel}
          loading={config.loading}
          searchBy={config.searchBy}
          separator={config.separator}
          clearable={config.clearable}
          searchable={config.searchable}
          create={config.create}
          keepOpen={config.forceOpen}
          dropdownHandle={config.handle}
          dropdownHeight={`${dropdownHeight}px`}
          direction={config.direction}
          multi={config.multi}
          values={selectValues}
          labelField={config.labelField}
          valueField={config.valueField}
          dropdownGap={5}
          keepSelectedInList={config.keepSelectedInList}
          onDropdownOpen={() => undefined}
          onDropdownClose={() => undefined}
          selectAll={config.selectAll}
          onClearAll={() => onClearAll()}
          onSelectAll={() => onSelectAll()}
          noDataLabel="No matches found"
          closeOnSelect={config.closeOnSelect}
          noDataRenderer={
            config.noDataRenderer ? () => this.noDataRenderer() : undefined
          }
          dropdownPosition={config.dropdownPosition}
          itemRenderer={
            config.itemRenderer
              ? (item, itemIndex, props, state, methods) =>
                  itemRenderer(item, itemIndex, props, state, methods)
              : undefined
          }
          contentRenderer={(props, state, methods) =>
            contentRenderer(props, state, methods)
          }
          clearRenderer={(props, state, methods) =>
            clearRenderer(props, state, methods)
          }
          style={tstyle}
        />
      </div>
    );
  } else return null;
};
