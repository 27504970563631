export const config = (filter) => {
  return {
    fontSize: filter.fontSize,
    color: filter.textColor,
    fontWeight: filter.fontWeight,
    paddingTop: filter.paddingTop,
    paddingHorizontal: filter.paddingHorizontal,
    lineHeight:filter.lineHeight
  };
};
