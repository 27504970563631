import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";
import { constructData } from "./utils/constructdata";

function App() {
  const [constructedData, setConstructedData] = useState();

  useEffect(() => {
    setConstructedData(constructData());
  }, []);

  return constructedData ? (
    <div style={{ fontFamily: "Graphic", height: "100vh", width: "100vw" }}>
      <Routes>
        <Route exact path="/" element={<Dashboard constructedData={constructedData} />} />  
      </Routes>
    </div>
  ) : null;
}
const RoutedApp = () => (
  <Router>
    <App></App>
  </Router>
);

export default RoutedApp;
