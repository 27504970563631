export const config = (filter) => {
  return {
    content:
      "At the forefront of our mission to enhance family well-being, the Light Lab and the Center for Learning serve as the convergence between data, insights, and stories. These dynamic platforms illuminate key topics within the realm of family well-being. Designed with accessibility in mind, our website invites you to explore and engage with the latest research and stories, all presented in an easily digestible format. Additionally, the Shine on NC Campaign creates an online opportunity for individuals in the state to share their stories, creating understanding of a diverse set of issues through individual experience. Delve deeper into the Light Lab, the Center for Learning, and the Shine on NC Campaign by simply clicking on the respective banners, guiding you to dedicated landing pages on the Institute for Family website.",
    header: {
      fontSize: filter.headerFontSize,
      fontWeight: filter.headerFontWeight,
      color: filter.headerColor,
    },
    container: {
      padding: {
        left: filter.containerpaddingLeft,
        right: filter.containerpaddingRight,
        top: filter.containerpaddingTop,
        bottom: filter.containerpaddingBottom,
      },
      gradient1: filter.containerGradient1,
      gradient2: filter.containerGradient2,
      angle: filter.containerGradientAngle,
    },
    image: {
      height: {
        image1: filter.image1Height,
        image2: filter.image2Height,
        image3: filter.image3Height,
      },
      gap: filter.imageGap,
      margin: {
        top: {
          centerLearning: filter.centerLearningMarginTop,
          lightLab: filter.lightLabMarginTop,
        },
      },
      border: {
        color: filter.imageBorderColor,
        width: filter.imageBorderWidth,
      },
      transparentBackground: filter.imageTransparent,
    },
  };
};
