export const config = (filter) => {
  return {
    headertitle: {
      fontSize: filter.headertitleFontSize,
      color: filter.headertitleTextColor,
      fontWeight: filter.headertitleFontWeight,
      paddingTop: filter.headertitlePaddingTop,
      paddingBottom: filter.headertitlePaddingBottom,
      paddingHorizontal: filter.headertitlePaddingHorizontal,
    },
    title: {
      fontSize: filter.titleFontSize,
      color: filter.titleTextColor,
      fontWeight: filter.titleFontWeight,
      paddingTop: filter.titlePaddingTop,
      paddingBottom: filter.titlePaddingBottom,
      paddingHorizontal: filter.titlePaddingHorizontal,
    },
    subtitle: {
      fontSize: filter.subtitleFontSize,
      color: filter.subtitleTextColor,
      fontWeight: filter.subtitleFontWeight,
      paddingTop: filter.subtitlePaddingTop,
      paddingBottom: filter.subtitlePaddingBottom,
      paddingHorizontal: filter.subtitlePaddingHorizontal,
    },
    county: {
      fontSize: filter.countyFontSize,
      color: filter.countyTextColor,
      fontWeight: filter.countyFontWeight,
      paddingTop: filter.countyPaddingTop,
      paddingBottom: filter.countyPaddingBottom,
      paddingHorizontal: filter.countyPaddingHorizontal,
    },
    value: {
      fontSize: filter.valueFontSize,
      color: filter.valueTextColor,
      selectedColor: filter.valueSelectedColor,
      fontWeight: filter.valueFontWeight,
      paddingTop: filter.valuePaddingTop,
      paddingBottom: filter.valuePaddingBottom,
      paddingHorizontal: filter.valuePaddingHorizontal,
    },
    footer: {
      width: filter.footerWidth,
      height: filter.footerHeight,
      color: filter.footerColor,
      padding: { top: filter.footerPaddingTop },
    },
  };
};
