export const config = (filter) => {
  return {
    logo: {
      height: filter.logoHeight,
      margin: { top: filter.logoMarginTop, bottom: filter.logoMarginBottom },
    },
    header: {
      fontSize: filter.headerFontSize,
      fontWeight: filter.headerFontWeight,
      color: filter.headerColor,
      content: [
        "Family Insight Survey",
        "Responses Sparked Conversation",
        "Nexus Dashboard",
      ],
      height: filter.headerHeight,
      padding: { horizontal: filter.headerPadding },
      lineHeight: filter.headerLineHeight,
    },
    svg: {
      stroke: filter.svgStroke,
      size: filter.svgSize,
      margin: { top: filter.svgMarginTop, bottom: filter.svgMarginBottom },
      strokeWidth: filter.svgStrokeWidth,
    },
    text: {
      fontSize: filter.textFontSize,
      fontWeight: filter.textFontWeight,
      color: filter.textColor,
      content: [
        <span>From November 2023 to February 2024, the pilot Family Insight Survey was launched in NC. The pilot survey honed in on seven pivotal dimensions crucial for family well-being. These dimensions were carefully selected by the Institute after thorough analysis of child welfare research in the field. We compiled common themes representing a broad spectrum of factors essential for families to thrive. Each dimension provides a comprehensive framework for understanding the multifaceted needs of families in today’s world. <a href={"https://instituteforfamily.org/family-survey/"}>Click here</a> to learn more about the Family Insight Survey.</span>,
        "We received 288 responses to the Family Insight Survey, providing insight into families' feelings across the state. While many felt strong and connected, they expressed a need for more community support to thrive. We envisioned the Nexus Dashboard as a tool to link diverse data sets, insights, and projects, aiming to create a holistic view of community needs, identify gaps, and fortify families, ensuring resilience and support, especially during crises.",
        "The Institute for Family Nexus Dashboard was developed to help advocates and allies better understand families' positions within the essential elements of family well-being on a county level in North Carolina. A Nexus represents a connection point between multiple entities. Our Nexus Dashboard integrates data, insights, and other IFF initiatives to foster a comprehensive understanding of what is needed to strengthen families, ensuring they remain united and supported.",
      ],
      padding: { horizontal: filter.textPadding, top: filter.textPaddingTop },
      lineHeight: filter.textLineHeight,
    },
    container: {
      padding: {
        left: filter.containerpaddingLeft,
        right: filter.containerpaddingRight,
        top: filter.containerpaddingTop,
        bottom: filter.containerpaddingBottom,
      },
      border: {
        color: filter.borderColor,
        width: filter.borderWidth,
        radius: filter.borderRadius,
      },
      backgroundColor: filter.backgroundColor,
      height: filter.containerHeight,
      width: filter.containerWidth,
      gap: filter.containerGap,
    },
  };
};
