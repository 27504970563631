import React, { useState } from "react";
import { config as Top5Config } from "./top5/config";

export const Top5 = ({ filter, county, measure, constructedData }) => {
  const config = Top5Config(filter);

  const [showall, setShowall] = useState(false);

  if (county && measure && measure.label) {
    const title =
      measure && measure.label && constructedData.cardData[measure.label]
        ? constructedData.cardData[measure.label].title
        : "";

    const value =
      measure && measure.label
        ? constructedData.refData[measure.label]
          ? constructedData.refData[measure.label][county]
          : ""
        : "";

    const displayItems = value
      .sort((a, b) => {
        return a.Rank > b.Rank ? 1 : a.Rank < b.Rank ? -1 : 0;
      })
      .map((item) => item["Company Name"]);

    const style = {
      // height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    };

    const titlestyle = {
      fontSize: config.title.fontSize,
      fontWeight: config.title.fontWeight,
      color: config.title.color,
      paddingTop: config.title.paddingTop,
      paddingBottom: config.title.paddingBottom,
      paddingLeft: config.title.paddingHorizontal,
      paddingRight: config.title.paddingHorizontal,
    };

    const valuestyle = {
      fontSize: config.value.fontSize,
      fontWeight: config.value.fontWeight,
      color: config.value.color,
      // paddingTop: config.value.paddingTop,
      // paddingBottom: config.value.paddingBottom,
      // paddingLeft: config.value.paddingHorizontal,
      // paddingRight: config.value.paddingHorizontal,
    };

    const expandedstyle = {
      fontSize: config.expanded.fontSize,
      fontWeight: config.expanded.fontWeight,
      color: config.expanded.color,
      paddingTop: config.expanded.paddingTop,
      paddingBottom: config.expanded.paddingBottom,
      paddingLeft: config.expanded.paddingHorizontal,
      paddingRight: config.expanded.paddingHorizontal,
    };

    const footerstyle = {
      borderBottom: `${config.footer.value.height}px solid ${config.footer.value.color}`,
      width: config.footer.value.width,
    };
    const footerexpandedstyle = {
      borderBottom: `${config.footer.expanded.height}px solid ${config.footer.expanded.color}`,
      width: config.footer.expanded.width,
    };
    return (
      <div onClick={() => setShowall(!showall)} style={style}>
        <div style={titlestyle}>{title}</div>
        <div>
          {showall ? (
            value.map((item, j) => {
              return (
                <div style={expandedstyle} key={j}>
                  <table>
                    <tbody>
                      {Object.entries(item)
                        .filter((filt) => filt[0] !== "Month")
                        .map((row, r) => {
                          return (
                            <tr key={r}>
                              <td
                                style={{
                                  paddingTop: config.expanded.paddingTop,
                                  paddingBottom: config.expanded.paddingBottom,
                                  paddingLeft:
                                    config.expanded.paddingHorizontal,
                                  textAlign: "left",
                                }}
                              >
                                {row[0]}
                              </td>
                              <td>:</td>
                              <td
                                style={{
                                  paddingTop: config.expanded.paddingTop,
                                  paddingBottom: config.expanded.paddingBottom,
                                  paddingRight:
                                    config.expanded.paddingHorizontal,
                                  textAlign: "left",
                                }}
                              >
                                {row[1]}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: config.footer.expanded.paddingTop,
                      paddingBottom: config.footer.expanded.paddingBottom,
                    }}
                  >
                    <div style={footerexpandedstyle}></div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="single" style={valuestyle}>
              <table>
                <tbody>
                  {displayItems.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td
                          style={{
                            paddingTop: config.value.paddingTop,
                            paddingBottom: config.value.paddingBottom,
                            paddingRight: config.value.paddingHorizontal,
                            textAlign: "right",
                          }}
                        >
                          {i + 1}
                        </td>
                        <td
                          style={{
                            paddingTop: config.value.paddingTop,
                            paddingBottom: config.value.paddingBottom,
                            textAlign: "left",
                          }}
                        >
                          {item}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: config.footer.value.paddingTop,
                  paddingBottom: config.footer.value.paddingBottom,
                }}
              >
                <div style={footerstyle}></div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else return null;
};
