import { config as NavConfig } from "./config";

export const NavigationTipsConfig = ({ filter }) => {
  const config = NavConfig(filter);
  const containerstyle = {
    // paddingLeft: config.container.padding.left,
    // paddingRight: config.container.padding.right,
    paddingTop: config.container.padding.top,
    paddingBottom: config.container.padding.bottom,
    background: `linear-gradient(${config.container.angle}deg, ${config.container.gradient1} 0%, ${config.container.gradient2} 100%)`,
  };
  const itemStyle = {
    height: config.stepgroup.height,
    // width: config.stepgroup.width,
    backgroundColor: config.stepgroup.backgroundColor,
    borderTopRightRadius: config.step.radius,
    borderBottomRightRadius: config.step.radius,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: config.stepgroup.margin,
  };
  const stepstyle = {
    height: "100%",
    minWidth: config.step.width,
    backgroundColor: "#07B8BE",
    borderTopRightRadius: config.step.radius,
    borderBottomRightRadius: config.step.radius,
    display: "flex",
    alignItems: "center",
    paddingLeft: config.step.padding.left,
  };

  const tipGroup = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: config.container.padding.left,
    paddingRight: config.container.padding.right,
  };

  return (
    <div style={containerstyle}>
      <div
        style={{
          textAlign: "center",
          fontSize: config.header.fontSize,
          fontWeight: config.header.fontWeight,
          color: config.header.color,
        }}
      >
        {config.header.text}
      </div>
      <div
        style={{
          textAlign: "center",
          fontSize: config.subheader.fontSize,
          fontWeight: config.subheader.fontWeight,
          color: config.subheader.color,
        }}
      >
        {config.subheader.text}
      </div>
      <div style={tipGroup}>
        <div>
          {config.content.map((item, i) => {
            return (
              <div key={i} style={itemStyle}>
                <div style={{ ...stepstyle, backgroundColor: item.color }}>
                  <div>
                    <div
                      style={{
                        fontSize: config.step.step.fontSize,
                        fontWeight: config.step.step.fontWeight,
                        color: config.step.step.color,
                      }}
                    >
                      Tip
                    </div>
                    <div
                      style={{
                        fontSize: config.step.number.fontSize,
                        fontWeight: config.step.number.fontWeight,
                        color: config.step.number.color,
                      }}
                    >
                      {item.step}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingLeft: config.stepgroup.padding.left,
                    paddingRight: config.stepgroup.padding.right,
                  }}
                >
                  <div
                    style={{
                      fontSize: config.step.header.fontSize,
                      fontWeight: config.step.header.fontWeight,
                      color: item.color,
                      marginBottom: config.stepgroup.padding.bottom,
                    }}
                  >
                    {item.header}
                  </div>
                  <div
                    style={{
                      fontSize: config.step.content.fontSize,
                      fontWeight: config.step.content.fontWeight,
                      color: config.step.content.color,
                    }}
                  >
                    {item.text}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
