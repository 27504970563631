export const config = (filter) => {
  return {
    header: {
      fontSize: filter.headerFontSize,
      fontWeight: filter.headerFontWeight,
      color: filter.headerColor,
      padding: { bottom: filter.headerPaddingBottom },
    },
    text: {
      fontSize: filter.textFontSize,
      fontWeight: filter.textFontWeight,
      color: filter.textColor,
      content: [
        {
          title: "Top 5 Employers",
          source: "North Carolina Department of Commerce.",
          link: "https://d4.nccommerce.com/QCEWLargestEmployers.aspx",
        },
        {
          title: "Voter Turnout",
          source: "North Carolina State Board of Elections.",
          link: "https://www.ncsbe.gov/results-data/voter-turnout/2020-general-election-turnout",
        },
        {
          title: "Polling Locations",
          source: "North Carolina Department of Commerce.",
          link: "https://www.ncsbe.gov/results-data/polling-place-data",
        },
        {
          title: "Grocery Stores",
          source: "U.S Census Bureau: Economic Census.",
          link: "https://www2.census.gov/programs-surveys/economic-census/data/2017/",
        },
        {
          title: "Median Family Income",
          source: "The Annie E. Casey Foundation: Kids Count Data Center.",
          link: "https://datacenter.aecf.org/data?location=NC#NC/2/0/char/0",
        },
        {
          title: "Children under age 18 with no parent in the labor force.",
          source: "The Annie E. Casey Foundation: Kids Count Data Center.",
          link: "https://datacenter.aecf.org/data?location=NC#NC/2/0/char/1",
        },
        {
          title:
            "Housing Units where Householders spend at least 30% of income on housing.",
          source: "The Annie E. Casey Foundation: Kids Count Data Center.",
          link: "https://datacenter.aecf.org/data?location=NC#NC/2/0/char/2",
        },
        {
          title: "Children below 100% poverty.",
          source: "The Annie E. Casey Foundation: Kids Count Data Center.",
          link: "https://datacenter.aecf.org/data?location=NC#NC/2/0/char/3",
        },
        {
          title: "Average of School Performance Grades.",
          source: "NC School Report Cards.",
          link: "https://ncreports.ondemand.sas.com/src/",
        },
        {
          title: "Total number of child care facilities in North Carolina.",
          source:
            "The Annie E. Casey Foundation: Kids Count Data Center.",
          link: "https://datacenter.aecf.org/data/tables/9960-total-number-of-child-care-facilities?loc=35&loct=2#detailed/5/4910-5009/false/1095/any/19308",
        },
        {
          title: "Births to women receiving very late or no prenatal care.",
          source:
            "The Annie E. Casey Foundation.",
          link: "https://datacenter.aecf.org/data/tables/7634-births-to-women-receiving-very-late-or-no-prenatal-care#detailed/5/4910-5009/false/1095/any/15530,14781",
        },
        {
          title: "Uninsured populations by age group in NC",
          source: "U.S. Census Bureau.",
          link: "https://www.census.gov/data-tools/demo/sahie/#/?s_statefips=37&s_year=2020&state_county=37000,37001,37003,37005,37007,37009,37011,37013,37015,37017,37019,37021,37023,37025,37027,37029,37031,37033,37035,37037,37039,37041,37043,37045,37047,37049,37051,37053,37055,37057,37059,37061,37063,37065,37067,37069,37071,37073,37075,37077,37079,37081,37083,37085,37087,37089,37091,37093,37095,37097,37099,37101,37103,37105,37107,37109,37111,37113,37115,37117,37119,37121,37123,37125,37127,37129,37131,37133,37135,37137,37139,37141,37143,37145,37147,37149,37151,37153,37155,37157,37159,37161,37163,37165,37167,37169,37171,37173,37175,37177,37179,37181,37183,37185,37187,37189,37191,37193,37195,37197,37199&s_searchtype=sc&AGECAT=0",
          display: "https://www.census.gov/data-tools/demo/sahie/#/",
        },
        {
          title:
            "Child Abuse and Neglect Reports Investigated and Substantiated in NC",
          source: "UNC School of Social Work.",
          link: "https://ssw.unc.edu/ma/",
        },
        {
          title: "Children under the age of 18 in Foster Care",
          source: "UNC School of Social Work.",
          link: "https://ssw.unc.edu/ma/",
        },
      ],
      padding: { top: filter.textPaddingTop, bottom: filter.textPaddingBottom },
    },
    border: {
      color: filter.borderColor,
      width: filter.borderWidth,
    },
    lineBorder: {
      color: filter.lineBorderColor,
      width: filter.lineBorderWidth,
    },
    backgroundColor: filter.backgroundColor,
    padding: {
      horizontal: filter.paddingHorizontal,
      top: filter.paddingTop,
    },
  };
};
