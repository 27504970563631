import { useRef, useEffect, useState } from "react";
import { config as exploreConfig } from "./config";
import infoGraphic2 from "../../images/scaled/lightLab.png";
import infoGraphic1 from "../../images/scaled/learning.png";
import infoGraphic3 from "../../images/scaled/Shine.png";
import { calculateAspectRatioFit } from "../../utils/imagefunctions";

export const ExploreConfig = ({ filter }) => {
  const boxRef = useRef();
  const [boxWidth, setBoxWidth] = useState();

  useEffect(() => {
    if (!boxRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setBoxWidth(boxRef.current.getBoundingClientRect().width);
    });
    resizeObserver.observe(boxRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const config = exploreConfig(filter);

  const containerstyle = {
    boxSizing: "border-box",
    width: "100%",
    paddingLeft: config.container.padding.left,
    paddingRight: config.container.padding.right,
    paddingTop: config.container.padding.top,
    paddingBottom: config.container.padding.bottom,
    background: `linear-gradient(${config.container.angle}deg, ${config.container.gradient1} 0%, ${config.container.gradient2} 100%)`,
  };

  return  (
    <div style={containerstyle} ref={boxRef}>
      <div
        style={{
          fontSize: config.header.fontSize,
          fontWeight: config.header.fontWeight,
          color: config.header.color,
          textAlign: "center",
        }}
      >
        {config.content}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: config.image.gap,
        }}
      >
        <a href={"https://instituteforfamily.org/light-lab/"}>
          <img
            style={{
              ...calculateAspectRatioFit(
                393,
                566,
                3000,
                (boxWidth / 1193) * config.image.height.image2
              ),
              border: `${config.image.border.width}px solid ${config.image.border.color}`,
              marginTop: config.image.margin.top.lightLab,
            }}
            alt="infoGraphic2"
            src={infoGraphic2}
          />
        </a>
        <a href={"https://instituteforfamily.org/center-for-learning/"}>
          <img
            style={{
              ...calculateAspectRatioFit(
                440,
                566,
                3000,
                (boxWidth / 1193) * config.image.height.image1
              ),
              border: `${config.image.border.width}px solid ${config.image.border.color}`,
              marginTop: config.image.margin.top.centerLearning,
            }}
            alt="infoGraphic1"
            src={infoGraphic1}
          />
        </a>
        <a href={"https://instituteforfamily.org/shine-north-carolina/"}>
          <img
            style={{
              ...calculateAspectRatioFit(
                505,
                566,
                3000,
                (boxWidth / 1193) * config.image.height.image3
              ),
              border: `${config.image.border.width}px solid ${config.image.border.color}`,
              marginTop: config.image.margin.top.centerLearning,
            }}
            alt="infoGraphic3"
            src={infoGraphic3}
          />
        </a>
      </div>
    </div>
  );
};
