import React from "react";
import { config as MultiConfig } from "./config";
import { MultiCardSelector } from "./MultiCardSelector";

export const MultiPanelConfig = ({
  filter,
  quotesFilter,
  doubleFilter,
  top5Filter,
  storiesFilter,
  county,
  measures,
  constructedData,
  setGValues,
  selectGValues,
  rightbox,
}) => {

  if (rightbox && rightbox.height > 0) {
    const config = MultiConfig(filter);

    const style = {
      display: "inline-block",
      position: "relative",
      height: rightbox.height,
      width: "100%",
      border: `${config.parent.border.width}px solid ${config.parent.border.color}`,
      backgroundColor: config.parent.background,
      fontSize: config.parent.text.fontSize,
      color: config.parent.text.color,
      borderRadius: config.parent.border.radius,
      paddingTop: config.parent.padding.top,
      paddingBottom: config.parent.padding.bottom,
      paddingLeft: config.parent.padding.left,
      paddingRight: config.parent.padding.right,
      boxSizing: "border-box",
    };

    const innerstyle = {
      border: `${config.inner.border.width}px solid ${config.inner.border.color}`,
      backgroundColor: config.inner.background,
      fontSize: config.inner.text.fontSize,
      fontWeight: config.inner.text.fontWeight,
      color: config.inner.text.color,
      borderRadius: config.inner.border.radius,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      overflow: "auto",
    };

    const countystyle = {
      height: config.parent.padding.top,
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      top: 0,
      left: 0,
      width: "100%",
    };
    return (
      <div style={style}>
        <div style={countystyle}>{county}</div>
        <div style={innerstyle}>
          {measures && measures.length > 0
            ? measures.map((item, i) => {
                return (
                  <MultiCardSelector
                    quotesFilter={quotesFilter}
                    doubleFilter={doubleFilter}
                    top5Filter={top5Filter}
                    storiesFilter={storiesFilter}
                    county={county}
                    measure={item}
                    constructedData={constructedData}
                    key={i}
                    setGValues={setGValues}
                    selectGValues={selectGValues}
                  />
                );
              })
            : null}
        </div>
      </div>
    );
  } else return null;
};
