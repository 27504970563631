import React, { useEffect, useRef } from "react";
import { draw } from "./draw";

export const NorthCarolinaChart = ({
  width,
  height,
  filter,
  setCounty,
  mapbox,
  getGradient,
  getLabel,
  getColor,
  getFontSize,
  getFontWeight
}) => {
  const ref = useRef(null);

  const drawGraph = () => {
    draw(
      ref.current,
      filter,
      setCounty,
      mapbox,
      getGradient,
      getLabel,
      getColor,
      getFontSize,
      getFontWeight
    );
  };
  useEffect(drawGraph, [
    filter,
    setCounty,
    mapbox,
    getGradient,
    getLabel,
    getColor,
    getFontSize,
    getFontWeight
  ]);

  return <svg ref={ref} width={width} height={height}></svg>;
};


// return <svg ref={ref} width={"100%"} height={"100%"} viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="none"></svg>;