import * as React from "react";
const Welcome1 = (props) => {
  return (
    <svg
      id="svg1"
      width={84}
      height={68.800003}
      viewBox="0 0 84 68.800003"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs id="defs1" />
      <g id="g1">
        <path
          id="path2"
          d="m 40.523778,11.014576 a 2.3426523,2.3015532 0 0 1 -2.342652,2.301553 2.3426523,2.3015532 0 0 1 -2.342653,-2.301553 2.3426523,2.3015532 0 0 1 2.342653,-2.3015533 2.3426523,2.3015532 0 0 1 2.342652,2.3015533"
        />
        <path
          d="m 39.455199,22.275748 c -2.767344,2.602947 -5.534688,5.205894 -8.302032,7.808841 0,0 -3.698924,-3.370131 -3.698924,-3.370131"
          id="path3"
        />
        <path
          d="m 39.290802,39.783992 c -2.712545,2.46595 -5.42509,4.9319 -8.137635,7.39785 0,0 -3.616726,-3.205735 -3.616726,-3.205735"
          id="path4"
        />
        <path
          d="m 33.208126,23.919714 c -2.931741,0 -5.863481,0 -8.795222,0 0,0 -0.246595,10.439188 -0.246595,10.439188 0,0 10.932378,-0.164396 10.932378,-0.164396 0,0 0.0822,-7.39785 0.0822,-7.39785"
          id="path5"
        />
        <path
          d="m 33.372522,40.770372 c -3.068737,-0.0548 -6.137475,-0.109597 -9.206213,-0.164396 0,0 0.0822,10.767981 0.0822,10.767981 0,0 10.767981,0.246595 10.767981,0.246595 0,0 0.0822,-7.808842 0.0822,-7.808842"
          id="path6"
        />
        <path
          id="path7"
          d="m 31.975921,11.004846 a 6.2059741,5.8360815 0 0 1 6.223127,-5.7441281 6.2059741,5.8360815 0 0 1 6.187743,5.7778331"
        />
        <path
          d="m 31.728556,10.932378 c -1.342573,0.0274 -2.685145,0.0548 -4.027718,0.0822 0,0 0.0822,6.41147 0.0822,6.41147 0,0 20.796178,0.0822 20.796178,0.0822 0,0 -0.246595,-6.41147 -0.246595,-6.41147 0,0 -3.781124,-0.0822 -3.781124,-0.0822"
          id="path8"
        />
        <path
          d="m 25.481483,11.261171 c -1.698766,0 -3.397531,0 -4.411314,0.0137 -1.013783,0.0137 -1.34257,0.0411 -1.575467,0.1096 -0.232896,0.0685 -0.36989,0.178095 -0.520589,0.287694 -0.150698,0.109598 -0.315092,0.219194 -0.47949,0.356193 -0.164398,0.136998 -0.328792,0.301392 -0.438391,0.493191 -0.109598,0.191798 -0.164395,0.410989 -0.205495,7.987084 -0.0411,7.576096 -0.0685,22.508495 -0.0411,30.235143 0.0274,7.726648 0.109597,8.247227 0.232896,8.658222 0.123299,0.410995 0.287692,0.712382 0.493191,0.986379 0.205498,0.273997 0.452088,0.520587 0.739785,0.698685 0.287698,0.178097 0.616485,0.287693 0.945281,0.356192 0.328797,0.0685 0.657584,0.0959 6.548579,0.0959 5.890995,-10e-7 17.343733,-0.0274 23.234617,-0.0822 5.890884,-0.0548 6.21967,-0.136997 6.521067,-0.232896 0.301397,-0.0959 0.575386,-0.205495 0.780883,-0.356194 0.205497,-0.150699 0.342491,-0.342491 0.47949,-0.575389 0.136999,-0.232898 0.273993,-0.506887 0.383592,-0.780884 0.109598,-0.273997 0.191795,-0.547986 0.191794,-2.561883 -10e-7,-2.013897 -0.0822,-5.767545 -0.164396,-9.521269"
          id="path9"
        />
        <path
          d="m 58.278617,31.810754 c 0,-6.137475 0,-12.274951 -0.0548,-15.521788 -0.0548,-3.246838 -0.164396,-3.603024 -0.301395,-3.87702 -0.136999,-0.273996 -0.301392,-0.465789 -0.506891,-0.643887 -0.205498,-0.178098 -0.452088,-0.342491 -0.726085,-0.45209 -0.273998,-0.109598 -0.575385,-0.164395 -1.548082,-0.205495 -0.972697,-0.0411 -2.61663,-0.0685 -4.260597,-0.0959"
          id="path10"
        />
        <path
          d="m 38.879811,27.043251 c 2.959139,0.0274 5.918279,0.0548 8.877419,0.0822"
          id="path11"
        />
        <path
          d="m 38.797612,30.413382 c 4.575707,0.0274 9.151414,0.0548 13.727122,0.0822"
          id="path12"
        />
        <path
          d="m 39.126406,34.358902 c 4.520908,-0.0274 9.041816,-0.0548 13.562724,-0.0822"
          id="path13"
        />
        <path
          d="m 38.962009,43.976107 c 1.863162,0 3.726324,0 5.589486,0"
          id="path14"
        />
        <path
          d="m 58.717008,35.537079 c -3.342732,3.315332 -10.028197,9.945998 -16.713661,16.576663 0,0 -0.575388,4.192115 -0.575388,4.192115 0,0 5.014098,-0.657587 5.014098,-0.657587 6.603266,-6.685464 13.206532,-13.370928 16.576665,-16.809561 3.370133,-3.438632 3.507127,-3.630424 3.603026,-3.781122 0.0959,-0.150698 0.150696,-0.260294 0.205496,-0.397292 0.0548,-0.136999 0.109597,-0.301393 0.150697,-0.520591 0.0411,-0.219198 0.0685,-0.493187 0.0274,-0.739785 -0.0411,-0.246597 -0.150695,-0.465788 -0.287694,-0.684985 -0.136999,-0.219198 -0.301392,-0.438389 -0.465791,-0.630187 -0.164398,-0.191798 -0.328792,-0.356191 -0.49319,-0.50689 -0.164398,-0.150698 -0.328791,-0.287693 -0.53429,-0.397291 -0.205498,-0.109599 -0.452088,-0.191796 -0.794585,-0.219195 -0.342498,-0.0274 -0.78088,1e-6 -1.095976,0.0822 -0.315095,0.0822 -0.506888,0.219194 -0.698686,0.424693 -0.191798,0.205498 -0.38359,0.479487 -0.479488,0.616485 -0.0959,0.136997 -0.0959,0.136996 -3.438632,3.452329 z"
          id="path15"
        />
        <path
          d="m 59.59379,35.016489 c 1.205576,1.123377 2.411151,2.246754 3.616727,3.370132"
          id="path16"
        />
      </g>
    </svg>
  );
};
export default Welcome1;
