import { useRef, useEffect, useState } from "react";
import { config as welcomeConfig } from "./config";
import Welcome3 from "../../images/svg/Welcome3";
import Welcome1 from "../../images/svg/Welcome1";
import Welcome2 from "../../images/svg/Welcome2";
// import logo from "../../images/NexusLogoTCropped.png";
import logo from "../../images/NexusDashboardLogo_IFF (002).jpg"
import { calculateAspectRatioFit } from "../../utils/imagefunctions";

export const WelcomeConfig = ({ filter }) => {
  const boxRef = useRef();
  const [boxWidth, setBoxWidth] = useState();

  useEffect(() => {
    if (!boxRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setBoxWidth(boxRef.current.getBoundingClientRect().width);
    });
    resizeObserver.observe(boxRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const config = welcomeConfig(filter); 

  const imageProps = {
    opacity: 1,
    fill: "none",
    stroke: config.svg.stroke,
    strokeWidth: config.svg.strokeWidth,
    strokeDasharray: "none",
    strokeOpacity: 1,
    width: config.svg.size,
    height: config.svg.size,
  };

  const control = [
    { image: <Welcome1 {...imageProps} /> },
    { image: <Welcome2 {...imageProps} /> },
    { image: <Welcome3 {...imageProps} /> },
  ];

  const containerstyle = {
    boxSizing: "border-box",
    width: "100%",
    paddingLeft: config.container.padding.left,
    paddingRight: config.container.padding.right,
    paddingTop: config.container.padding.top,
    paddingBottom: config.container.padding.bottom,
    display: "flex",
    justifyContent: "center",
    gap: config.container.gap,
  };

  const headerStyle = {
    boxSizing: "border-box",
    display: "table-cell",
    // fontSize: `${config.header.fontSize}px`,
    fontSize: `${
      (boxWidth / config.container.width) * config.header.fontSize
    }px`,
    fontWeight: config.header.fontWeight,
    color: config.header.color,
    height: config.header.height,
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: `${config.header.lineHeight / 10}em`,
    paddingLeft: config.header.padding.horizontal,
    paddingRight: config.header.padding.horizontal,
    width: config.container.width,
  };

  const logoStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: config.logo.margin.top,
    paddingBottom: config.logo.margin.bottom,
  };

  const boxStyle = {
    boxSizing: "border-box",
    width: config.container.width,
    backgroundColor: config.container.backgroundColor,
    border: `${config.container.border.width}px solid ${config.container.border.color}`,
    borderRadius: config.container.border.radius,
    height: config.container.height,
  };

  const svgStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: config.svg.margin.top,
    paddingBottom: config.svg.margin.bottom,
  };
  const textStyle = {
    // fontSize: `${(boxWidth / config.container.width) * config.text.fontSize}px`,
    fontSize: `${
      (boxWidth / config.container.width +
        ((config.container.width - boxWidth) * 0.07) / 100) *
      config.text.fontSize
    }px`,

    fontWeight: config.text.fontWeight,
    color: config.text.color,
    paddingLeft: config.text.padding.horizontal,
    paddingRight: config.text.padding.horizontal,
    paddingTop: config.text.padding.top,
    lineHeight: `${config.text.lineHeight / 10}em`,
  };

  return (
    <div>
      <div style={logoStyle}>
        <img
          src={logo}
          alt="logo"
          style={calculateAspectRatioFit(5550, 2250, 3000, config.logo.height)}
          // style={calculateAspectRatioFit(1075, 243, 3000, config.logo.height)}
        ></img>
      </div>
      <div style={containerstyle}>
        {control.map((item, i) => {
          return (
            <div ref={boxRef} key={i} style={boxStyle}>
              <div style={svgStyle}>{item.image}</div>
              <div style={headerStyle}>{config.header.content[i]}</div>
              <div style={textStyle}>{config.text.content[i]}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
