import React from "react";
import { Double } from "../multipanel/cards/Double";
import { Top5 } from "../multipanel/cards/Top5";
import { Quotes } from "../multipanel/cards/Quotes";
import {Stories} from "../multipanel/cards/Stories";

export const MultiCardSelector = ({
  quotesFilter,
  doubleFilter,
  top5Filter,
  storiesFilter,
  county,
  measure,
  constructedData,
  setGValues,
  selectGValues,
}) => {
  const card =
    measure && measure.label && constructedData.cardData[measure.label]
      ? constructedData.cardData[measure.label].card
      : measure && measure.label === "Stories and more" ? "stories" : "";

  const selectCard = () => {
    switch (card) {
      case "quotes":
        return (
          <Quotes
            filter={quotesFilter}
            county={county}
            measure={measure}
            constructedData={constructedData}
            setGValues={setGValues}
            selectGValues={selectGValues}
          />
        );
        case "stories":
          return (
            <Stories
              filter={storiesFilter}
            />
          );
      case "top5":
        return (
          <Top5
            filter={top5Filter}
            county={county}
            measure={measure}           
            constructedData={constructedData}
            setGValues={setGValues}
            selectGValues={selectGValues}
          />
        );
      case "double":
        return (
          <Double
            filter={doubleFilter}
            county={county}
            measure={measure}        
            constructedData={constructedData}
            setGValues={setGValues}
            selectGValues={selectGValues}
          />
        );
      default:
        return null;
    }
  };
  return <>{selectCard(card)}</>;
};
