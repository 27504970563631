export const config = (filter) => {
  return {
    multi: filter.multi,
    disabled: filter.disabled,
    disabledLabel: filter.disabledLabel,
    loading: filter.loading,
    contentRenderer: filter.contentRenderer,
    dropdownRenderer: filter.dropdownRenderer,
    inputRenderer: filter.inputRenderer,
    itemRenderer: filter.itemRenderer,
    optionRenderer: filter.optionRenderer,
    noDataRenderer: filter.noDataRenderer,
    searchBy: filter.searchBy,
    clearable: filter.clearable,
    searchable: filter.searchable,
    create: filter.create,
    separator: filter.separator,
    forceOpen: filter.forceOpen,
    handle: filter.handle,
    placeholder: filter.placeholder,
    addPlaceholder: filter.addPlaceholder,
    labelField: filter.labelField,
    valueField: filter.valueField,
    color: filter.color,
    keepSelectedInList: filter.keepSelectedInList,
    closeOnSelect: filter.closeOnSelect,
    dropdownPosition: filter.dropdownPosition,
    direction: filter.direction,
    dropdownHeight: filter.dropdownHeight,
    indent: filter.indent,
    minWidth: filter.minWidth,
    maxWidth: filter.maxWidth,
    selectAll: filter.selectAll,
    headers: {
      margin: {
        left: filter.headers_marginLeft,
        right: filter.headers_marginRight,
      },
      backgroundColor: filter.headers_backgroundColor,
      color: filter.headers_color,
      padding: {
        top: filter.headers_paddingTop,
        bottom: filter.headers_paddingBottom,
        left: filter.headers_paddingLeft,
      },
    },
    items: {
      margin: { right: filter.items_marginRight },
      padding: {
        top: filter.items_paddingTop,
        bottom: filter.items_paddingBottom,
        left: filter.items_paddingLeft,
      },
    },
    text: {
      fontSize: filter.fontSize,
      fontWeight: filter.fontWeight,
      color: filter.fontColor,
    },
  };
};
