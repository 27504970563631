export const TabButtons = ({ filter, list, selected, setSelected }) => {
  const container = {
    display: "flex",
    gap: 20,
    fontSize: filter.tabFontSize,
    fontWeight: filter.tabFontWeight,
    backgroundColor: filter.tabBackgroundColorBack,
    boxSizing:"border-box",
    whiteSpace:"nowrap"
  };

  const button = {
    flex: 1,
    cursor: "pointer",
    paddingTop: 3,
    paddingBottom:3,
    paddingLeft:12,
    paddingRight:12,
    border: `${filter.tabBorderWidth}px solid ${filter.tabBorderColor}`,
    textAlign: "center",
    borderRadius: filter.tabBorderRadius,
  };

  const getBackground = (item) => {
    return filter.tabBackgroundColor
    // switch (item) {
    //   case "Data": {
    //     return filter.tabBackgroundColorData;
    //   }
    //   case "Insights": {
    //     return filter.tabBackgroundColorInsights;
    //   }
    //   case "Stories and more": {
    //     return filter.tabBackgroundColorStories;
    //   }
    //   case "How to Navigate": {
    //     return filter.tabBackgroundColorNavigation;
    //   }
    //   case "Explore More": {
    //     return filter.tabBackgroundColorExplore;
    //   }
    //   default:
    //     return null;
    // }
  };
  const gettextColor = (item) => {
    return filter.tabFontColor
    // switch (item) {
    //   case "Data": {
    //     return filter.tabFontColorData;
    //   }
    //   case "Insights": {
    //     return filter.tabFontColorInsights;
    //   }
    //   case "Stories": {
    //     return filter.tabFontColorStories;
    //   }
    //   case "How to Navigate": {
    //     return filter.tabFontColorNavigation;
    //   }
    //   case "Explore More": {
    //     return filter.tabFontColorExplore;
    //   }
    //   default:
    //     return null;
    // }
  };

  const onClick = (e, item) => {
    e.stopPropagation();
    setSelected(item);
  };

  return (
    <div style={container}>
      {list.map((item, index) => (
        <div
          style={{
            ...button,
            color:
              selected === item
                ? filter.tabFontColorSelected
                : gettextColor(item),
            backgroundColor:
              selected === item
                ? filter.tabBackgroundColorSelected
                : getBackground(item),
          }}
          key={index}
          onClick={(e) => onClick(e, item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};
