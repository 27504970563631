import React, { useState } from "react";
import { config as QuotesConfig } from "./quotes/config";

export const Quotes = ({ filter, county, measure, constructedData }) => {
  const config = QuotesConfig(filter);

  const [selected, setSelected] = useState();

  if (county && measure && measure.label) {
    const title = measure.label;

    const value =
      measure && measure.label
        ? constructedData.quoteData[county]
          ? constructedData.quoteData[county].map((item, i) => {
              return { index: item.index, value: item[measure.label] };
            })
          : ""
        : "";

    const selectedData = (index) => {
      return constructedData.quoteData[county].find((item) => {
        return item.index === index;
      });
    };

    const style = {
      // height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    };

    const titlestyle = {
      fontSize: config.title.fontSize,
      fontWeight: config.title.fontWeight,
      color: config.title.color,
      paddingTop: config.title.paddingTop,
      paddingBottom: config.title.paddingBottom,
      paddingLeft: config.title.paddingHorizontal,
      paddingRight: config.title.paddingHorizontal,
    };

    const valuestyle = {
      fontSize: config.value.fontSize,
      fontWeight: config.value.fontWeight,
      color: config.value.color,
      lineHeight:`${config.value.lineHeight/10}em`
    };

    const expandedstyle = {
      fontSize: config.expanded.fontSize,
      fontWeight: config.expanded.fontWeight,
      paddingTop: config.expanded.paddingTop,
      paddingBottom: config.expanded.paddingBottom,
      paddingLeft: config.expanded.paddingHorizontal,
      paddingRight: config.expanded.paddingHorizontal,
    };

    const footerstyle = {
      borderBottom: `${config.footer.value.height}px solid ${config.footer.value.color}`,
      width: config.footer.value.width,
    };
    const dividerstyle = {
      borderBottom: `${config.footer.divider.height}px solid ${config.footer.divider.color}`,
      width: config.footer.divider.width,
    };
    const footerexpandedstyle = {
      borderBottom: `${config.footer.expanded.height}px solid ${config.footer.expanded.color}`,
      width: config.footer.expanded.width,
    };
    return (
      <div style={style}>
        <div style={titlestyle}>{title}</div>
        <div>
          <div style={valuestyle}>
            {value.map((item, j) => {
              if (item.index === selected) {
                return (
                  <div
                    style={{
                      ...expandedstyle,
                      color: config.expanded.color.question,
                    }}
                    key={j}
                    onClick={() => setSelected(null)}
                  >
                    {Object.entries(selectedData(item.index))
                      .filter((item) => item[0] !== "index")
                      .map((row, r) => {
                        return (
                          <div key={r}>
                            <div
                              style={{
                                paddingTop: config.expanded.paddingTop,
                                paddingBottom: config.expanded.paddingBottom,
                                paddingLeft: config.expanded.paddingHorizontal,
                                textAlign: "left",
                              }}
                            >
                              {row[0]}
                            </div>
                            <div
                              key={r}
                              style={{
                                ...expandedstyle,
                                color: config.expanded.color.answer,
                              }}
                            >
                              {row[1].map((itemrow,ir)=>{
                                return <div key={ir}>{itemrow}</div>
                              })}
                            </div>
                          </div>
                        );
                      })}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: config.footer.expanded.paddingTop,
                        paddingBottom: config.footer.expanded.paddingBottom,
                      }}
                    >
                      <div style={footerexpandedstyle}></div>
                    </div>
                  </div>
                );
              } else {
                return item.value.map((det, d) => {
                  return (
                    <div key={`${j}${d}`}
                    onClick={() =>
                      setSelected(item.index === selected ? null : item.index)
                    }>
                    <div
                      style={{
                        paddingTop: config.value.paddingTop,
                        paddingBottom: config.value.paddingBottom,
                        paddingRight: config.value.paddingHorizontal,
                        textAlign: "left",
                      }}
                      
                    >
                      {det}
                    </div>
                    <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: config.footer.divider.paddingTop,
                      paddingBottom: config.footer.divider.paddingBottom,
                    }}
                  >
                    <div style={dividerstyle}></div>
                  </div>
                  </div>
                  );
                });
              }
            })}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: config.footer.value.paddingTop,
                paddingBottom: config.footer.value.paddingBottom,
              }}
            >
              <div style={footerstyle}></div>
            </div>
          </div>
        </div>
      </div>
    );
  } else return null;
};
