import { config as resourcesConfig } from "./config";

export const ResourcesConfig = ({ filter }) => {
  const config = resourcesConfig(filter);

  const containerStyle = {
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    width: "100%",

    backgroundColor: config.backgroundColor,
    border: `${config.border.width}px solid ${config.border.color}`,
    paddingLeft: config.padding.horizontal,
    paddingRight: config.padding.horizontal,
    paddingTop: config.padding.top,
  };

  const tableStyle = { border: "none" };
  const thStyle = {
    textAlign: "left",
    fontSize: `${config.header.fontSize}px`,
    fontWeight: config.header.fontWeight,
    color: config.header.color,
  };
  const detailStyle = {
    borderTop: `${config.lineBorder.width}px solid ${config.lineBorder.color}`,
    fontSize: `${config.text.fontSize}px`,
    fontWeight: config.text.fontWeight,
    color: config.text.color,
    paddingTop: config.text.padding.top,
    paddingBottom: config.text.padding.bottom,
  };

  return (
    <div style={containerStyle}>
      <table style={tableStyle}>
        <thead style={thStyle}>
          <tr>
            <th style={{ paddingBottom: config.header.padding.bottom }}>
              Data
            </th>
            <th style={{ paddingBottom: config.header.padding.bottom }}>
              Resource
            </th>
            <th style={{ paddingBottom: config.header.padding.bottom }}>
              Link
            </th>
          </tr>
        </thead>
        <tbody>
          {config.text.content.map((item, i) => {
            return (
              <tr key={i}>
                <td style={detailStyle}>{item.title}</td>
                <td style={detailStyle}>{item.source}</td>
                <td style={detailStyle}>
                  <a href={item.link}>
                    {item.display ? item.display : item.link}
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
