export const config = (filter) => {
  return {
    box: {
      border: {
        radius: filter.borderRadius,
        width: filter.borderWidth,
        color: filter.borderColor,
      },
      height: filter.boxheight,
      width: filter.boxwidth,
    },
    arrow: {
      head: {
        length: filter.arrowHeadLength,
        width: filter.arrowHeadWidth,
        color: filter.arrowHeadColor,
      },
      shaft: {
        length: filter.arrowShaftLength,
        width: filter.arrowShaftWidth,
        color: filter.arrowShaftColor,
        offset: filter.arrowShaftOffset,
      },
    },
    text: {
      direction: {
        fontSize: filter.directionFontSize,
        color: filter.directionColor,
      },
      selection: {
        fontSize: filter.selectionFontSize,
        color: filter.selectionColor,
      },
      nodata: {
        fontSize: filter.noDataFontSize,
        color: filter.valueColorNoData,
      },
    },
    value: {
      fontSize: filter.valueFontSize,
      colorMax: filter.valueColorMax,
      colorMin: filter.valueColorMin,
    },
    margin: { noData: filter.marginNoData },
    popup: {
      backgroundColor: filter.popupBackground,
      height: filter.popupHeight,
      width: filter.popupWidth,
      radius: filter.popupRadius,
      offset: filter.popupOffset,
      arrow: { size: filter.popupPointerBox, offset: filter.popupArrowOffset },
      text: {
        fontSize: filter.popupFontSize,
        fontWeight: filter.popupFontWeight,
        color: filter.popupColor,
        lineHeight: filter.popupLineHeight,
      },
      value: {
        fontSize: filter.popupValueFontSize,
        fontWeight: filter.popupValueFontWeight,
        color: filter.popupValueColor,
      },
    },
  };
};
