export const config = (filter) => {
  return {
    container: {
      padding: {
        top: filter.containerpaddingTop,
        bottom: filter.containerpaddingBottom,
      },
    },
    image: {
      height: filter.imageHeight,
      border: {
        color: filter.imageBorderColor,
        width: filter.imageBorderWidth,
      },
    },
  };
};
