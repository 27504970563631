import React, { useState, useRef, useEffect } from "react";
import { MapChartConfig } from "./map/MapChartConfig";
import { MultiPanelConfig } from "./multipanel/MultiPanelConfig";
import { DropDownConfig } from "./dropdown/DropDownConfig";
import { NavigationTipsConfig } from "./navigationtips/NavigationTipsConfig";
import { ExploreConfig } from "./explore/ExploreConfig";
import { WelcomeConfig } from "./welcome/WelcomeConfig";
import { StoriesConfig } from "./stories/StoriesConfig";
import { ResourcesConfig } from "./resources/ResourcesConfig";
import { LegendConfig } from "./legend/LegendConfig";
import logo from "../images/SimpleT.png";
import { Loader } from "../components/Loader";
import { TabButtons } from "../components/TabButtons";
import { options, regionOptions } from "./dropdown/options";
import * as d3 from "d3";
import { calculateAspectRatioFit } from "../utils/imagefunctions";

const menuOptionsArray = [
  "Welcome",
  "How to Navigate",
  "Data",
  "Insights",
  "Stories",
  "Tools for Advocacy",
  "Resources",
];

const countiesToUseForRegionLabels = ["Davidson", "Pitt", "Buncombe"];

export const Dashboard = ({ constructedData, playoutFilter }) => {
  const [county, setCounty] = useState();
  const [mapbox, setMapbox] = useState();
  const [rightbox, setRightbox] = useState();
  const [dropdownbox, setDropdownbox] = useState();
  const [resourcesFilter, setResourcesFilter] = useState();
  const [selectValues, setValues] = useState();
  const [selectQuotesValue, setQuotesValue] = useState();
  const [selectGValues, setGValues] = useState(null);
  const [cardDoubleFilter, setCardDoubleFilter] = useState();
  const [cardQuotesFilter, setCardQuotesFilter] = useState();
  const [cardStoriesFilter, setCardStoriesFilter] = useState();
  const [cardTop5Filter, setCardTop5Filter] = useState();
  const [multifilter, setMultiFilter] = useState();
  const [navfilter, setNavFilter] = useState();
  const [exploreFilter, setExploreFilter] = useState();
  const [welcomefilter, setWelcomeFilter] = useState();
  const [storiesFilter, setStoriesFilter] = useState();
  const [layoutfilter, setLayoutFilter] = useState();
  const [mapfilter, setMapFilter] = useState();
  const [dropdownfilter, setDropDownFilter] = useState();
  const [dropdownquotesfilter, setDropDownQuotesFilter] = useState();
  const [domain, setDomain] = useState();
  const [average, setAverage] = useState();
  const [legendfilter, setLegendFilter] = useState();
  const mapRef = useRef(null);
  const dropdownRef = useRef(null);
  const rightRef = useRef(null);
  const [menuOptions, setMenuOptions] = useState("Welcome");
  const [colorfunction, setColorFunction] = useState();

  useEffect(() => {
    // load default config
    const asyncfunction = async () => {
      setMultiFilter({
        id: "MultiPanel",
        version: "default",
        innerBackground: "#ffffff",
        innerBorderColor: "#ffffff",
        innerBorderWidth: 1,
        innerFontSize: 27,
        innerFontWeight: 600,
        innerRadius: 18,
        innerTextColor: "#d0021b",
        parentBackground: "#ffffff",
        parentBorderColor: "#000000",
        parentBorderWidth: 0,
        parentFontSize: 20,
        parentHeight: 560,
        parentPaddingBottom: 21,
        parentPaddingLeft: 17,
        parentPaddingRight: 24,
        parentPaddingTop: 43,
        parentRadius: 20,
        parentTextColor: "#000000",
        parentWidth: 417,
        source: "live version",
      });
      setCardQuotesFilter({
        id: "MultiCardQuotes",
        version: "default",
        answerTextColor: "#f5a623",
        countyFontSize: 20,
        countyFontWeight: 600,
        countyPaddingBottom: 13,
        countyPaddingHorizontal: 0,
        countyPaddingTop: 0,
        countyTextColor: "#ffffff",
        dividerColor: "#ffffff",
        dividerHeight: 1,
        dividerPaddingBottom: 5,
        dividerPaddingTop: 5,
        dividerWidth: 120,
        expandedFontSize: 13,
        expandedFontWeight: 600,
        expandedPaddingBottom: 0,
        expandedPaddingHorizontal: 15,
        expandedPaddingTop: 0,
        footerColor: "#f5a623",
        footerExpandedColor: "#ffffff",
        footerExpandedHeight: 2,
        footerExpandedPaddingBottom: 13,
        footerExpandedPaddingTop: 13,
        footerExpandedWidth: 201,
        footerHeight: 2,
        footerPaddingBottom: 9,
        footerPaddingTop: 9,
        footerWidth: 180,
        questionTextColor: "#0f7cae",
        source: "live version",
        subtitleFontSize: 10,
        subtitleFontWeight: 600,
        subtitlePaddingBottom: 12,
        subtitlePaddingHorizontal: 0,
        subtitlePaddingTop: 0,
        subtitleTextColor: "#e28b3c",
        titleFontSize: 15,
        titleFontWeight: 700,
        titlePaddingBottom: 34,
        titlePaddingHorizontal: 20,
        titlePaddingTop: 18,
        titleTextColor: "#0f7cae",
        valueFontSize: 15,
        valueFontWeight: 200,
        valueLineHeight: 12,
        valuePaddingBottom: 2,
        valuePaddingHorizontal: 12,
        valuePaddingTop: 0,
        valueTextColor: "#000000",
      });
      setCardStoriesFilter({
        id: "MultiCardStories",
        version: "default",
        fontSize: 20,
        fontWeight: 600,
        lineHeight: 14,
        paddingHorizontal: 12,
        paddingTop: 0,
        source: "live version",
        textColor: "#4d4d4f",
      });
      setCardDoubleFilter({
        id: "MultiCardDouble",
        version: "default",
        countyFontSize: 20,
        countyFontWeight: 600,
        countyPaddingBottom: 10,
        countyPaddingHorizontal: 10,
        countyPaddingTop: 10,
        countyTextColor: "#ffffff",
        footerColor: "#f5a623",
        footerHeight: 2,
        footerPaddingTop: 11,
        footerWidth: 180,
        headertitleFontSize: 14,
        headertitleFontWeight: 600,
        headertitlePaddingBottom: 6,
        headertitlePaddingHorizontal: 20,
        headertitlePaddingTop: 18,
        headertitleTextColor: "#0d516e",
        source: "viewing draft 1",
        subtitleFontSize: 9,
        subtitleFontWeight: 600,
        subtitlePaddingBottom: 0,
        subtitlePaddingHorizontal: 20,
        subtitlePaddingTop: 2,
        subtitleTextColor: "#000000",
        titleFontSize: 12,
        titleFontWeight: 600,
        titlePaddingBottom: 0,
        titlePaddingHorizontal: 20,
        titlePaddingTop: 0,
        titleTextColor: "#000000",
        valueFontSize: 16,
        valueFontWeight: 600,
        valuePaddingBottom: 4,
        valuePaddingHorizontal: 11,
        valuePaddingTop: 8,
        valueSelectedColor: "#7ec9e8",
        valueTextColor: "#0f7cae",
      });
      setCardTop5Filter({
        id: "MultiCardTop5",
        version: "default",
        answerTextColor: "#f5a623",
        countyFontSize: 20,
        countyFontWeight: 600,
        countyPaddingBottom: 13,
        countyPaddingHorizontal: 0,
        countyPaddingTop: 0,
        countyTextColor: "#ffffff",
        dividerColor: "#4a90e2",
        dividerExpandedColor: "#417505",
        dividerExpandedHeight: 3,
        dividerExpandedPaddingBottom: 5,
        dividerExpandedPaddingTop: 8,
        dividerExpandedWidth: 207,
        dividerHeight: 2,
        dividerPaddingBottom: 5,
        dividerPaddingTop: 2,
        dividerWidth: 44,
        expandedFontSize: 13,
        expandedFontWeight: 600,
        expandedPaddingBottom: 0,
        expandedPaddingHorizontal: 15,
        expandedPaddingTop: 0,
        footerColor: "#f5a623",
        footerExpandedColor: "#ffffff",
        footerExpandedHeight: 2,
        footerExpandedPaddingBottom: 13,
        footerExpandedPaddingTop: 13,
        footerExpandedWidth: 201,
        footerHeight: 2,
        footerPaddingBottom: 9,
        footerPaddingTop: 9,
        footerWidth: 180,
        questionTextColor: "#0f7cae",
        source: "live version",
        subtitleFontSize: 10,
        subtitleFontWeight: 600,
        subtitlePaddingBottom: 12,
        subtitlePaddingHorizontal: 0,
        subtitlePaddingTop: 0,
        subtitleTextColor: "#e28b3c",
        titleFontSize: 14,
        titleFontWeight: 600,
        titlePaddingBottom: 6,
        titlePaddingHorizontal: 20,
        titlePaddingTop: 18,
        titleTextColor: "#0d5144",
        valueFontSize: 15,
        valueFontWeight: 600,
        valuePaddingBottom: 2,
        valuePaddingHorizontal: 12,
        valuePaddingTop: 0,
        valueTextColor: "#4d4d4f",
      });
      setMapFilter({
        id: "Map",
        version: "default",
        "Eastern-Coastal Region": "#7ec9e8",
        height: 335,
        labelColor: "#000000",
        labelColorSelected: "#a30b20",
        labelFontSize: 9,
        labelFontSizeSelected: 12,
        labelFontWeight: 700,
        labelFontWeightSelected: 700,
        labels: true,
        mapBackground: "#7fc7e4",
        mapGradientMax: "#d0021b",
        mapGradientMin: "#f8e71c",
        mapStroke: "#ffffff",
        mapStrokeWidth: 1,
        mapTransparentBackground: true,
        margin: 0,
        marginTop: 60,
        "Piedmont Region": "#efbb2a",
        source: "live version",
        svgBackgroundColor: "#ffffff",
        svgBorderColor: "#d0021b",
        svgBorderWidth: 0,
        tooltips: true,
        tooltipsBackgroundColor: "#182235",
        tooltipsBorderColor: "#ffffff",
        tooltipsBorderRadius: 4,
        tooltipsBorderWidth: 2,
        tooltipsFontSize: 14,
        tooltipsFontWeight: 600,
        tooltipsGap: 30,
        tooltipsHorizontalPadding: 10,
        tooltipsTextColor: "#ffffff",
        tooltipsVerticalPadding: 6,
        "Western-Mountain Region": "#b8e986",
        width: 877,
      });
      setNavFilter({
        id: "NavigationTips",
        version: "default",
        containerGradient1: "#ffffff",
        containerGradient2: "#7095C2",
        containerGradientAngle: 180,
        containerpaddingBottom: 16,
        containerpaddingLeft: 0,
        containerpaddingRight: 0,
        containerpaddingTop: 9,
        headerColor: "#4d4d4f",
        headerFontSize: 36,
        headerFontWeight: 800,
        source: "live version",
        step1Color: "#0d516e",
        step2Color: "#efba29",
        step3Color: "#4f2e54",
        step4Color: "#ee8622",
        step5Color: "#0f7cae",
        step6Color: "#82568b",
        step7Color: "#77168a",
        step8Color: "#d0021b",
        stepColor: "#ffffff",
        stepContentColor: "#4d4d4f",
        stepContentFontSize: 11,
        stepContentFontWeight: 400,
        stepFontSize: 12,
        stepFontWeight: 400,
        stepgroupBackground: "#ffffff",
        stepgroupHeight: 52,
        stepgroupInnerPaddingBottom: 2,
        stepgroupInnerPaddingLeft: 20,
        stepgroupInnerPaddingRight: 20,
        stepgroupMargin: 7,
        stepgroupWidth: 937,
        stepHeaderFontSize: 14,
        stepHeaderFontWeight: 600,
        stepNumberFontSize: 26,
        stepNumberFontWeight: 600,
        stepPaddingLeft: 20,
        stepRadius: 60,
        stepWidth: 60,
        subheaderColor: "#4d4d4f",
        subheaderFontSize: 16,
        subheaderFontWeight: 400,
      });
      setExploreFilter({
        id: "ExploreMore",
        version: "default",
        centerLearningMarginTop: 26,
        containerGradient1: "#e8e8ff",
        containerGradient2: "#f9f9fd",
        containerGradientAngle: 180,
        containerpaddingBottom: 41,
        containerpaddingLeft: 60,
        containerpaddingRight: 58,
        containerpaddingTop: 6,
        headerColor: "#4d4d4f",
        headerFontSize: 17,
        headerFontWeight: 400,
        image1Height: 375,
        image2Height: 375,
        image3Height: 375,
        imageBorderColor: "#000000",
        imageBorderWidth: 0,
        imageGap: 65,
        imageMarginTop: 35,
        imageTransparent: false,
        lightLabMarginTop: 26,
        source: "live version",
      });
      setWelcomeFilter({
        id: "Welcome",
        version: "default",
        backgroundColor: "#f0f0f0",
        borderColor: "#0d516e",
        borderRadius: 33,
        borderWidth: 0,
        containerGap: 20,
        containerHeight: 436,
        containerpaddingBottom: 0,
        containerpaddingLeft: 49,
        containerpaddingRight: 0,
        containerpaddingTop: 25,
        containerWidth: 353,
        headerColor: "#0d516e",
        headerFontSize: 26,
        headerFontWeight: 400,
        headerHeight: 56,
        headerLineHeight: 14,
        headerPadding: 28,
        logoHeight: 158,
        logoMarginBottom: 0,
        logoMarginTop: 14,
        source: "live version",
        svgMarginBottom: 0,
        svgMarginTop: 0,
        svgSize: 73,
        svgStroke: "#0d516e",
        svgStrokeWidth: 2,
        textColor: "#0d516e",
        textFontSize: 14,
        textFontWeight: 400,
        textLineHeight: 14,
        textPadding: 23,
        textPaddingTop: 9,
      });
      setStoriesFilter({
        id: "Stories",
        version: "default",
        containerpaddingBottom: 12,
        containerpaddingTop: 12,
        imageBorderColor: "#000000",
        imageBorderWidth: 0,
        imageHeight: 482,
        source: "live version",
      });

      setDropDownFilter({
        id: "DropDown",
        version: "default",
        addPlaceholder: "+ click to add",
        clearable: true,
        closeOnSelect: true,
        color: "#0f7cae",
        contentRenderer: false,
        create: false,
        direction: "ltr",
        disabled: false,
        disabledLabel: "",
        dropdownHeight: 500,
        dropdownPosition: "bottom",
        dropdownRenderer: false,
        fontColor: "#000000",
        fontSize: 16,
        fontWeight: 400,
        forceOpen: false,
        handle: true,
        headers_backgroundColor: "#FFFFFF",
        headers_color: "#000000",
        headers_marginLeft: 10,
        headers_marginRight: 10,
        headers_paddingBottom: 10,
        headers_paddingLeft: 0,
        headers_paddingTop: 10,
        indent: 53,
        inputRenderer: false,
        itemRenderer: true,
        items_marginRight: 10,
        items_paddingBottom: 3,
        items_paddingLeft: 10,
        items_paddingTop: 3,
        keepSelectedInList: true,
        labelField: "name",
        loading: false,
        maxWidth: 900,
        minWidth: 612,
        multi: true,
        noDataRenderer: false,
        optionRenderer: false,
        placeholder: "Select data to display",
        searchable: false,
        searchBy: "username",
        selectAll: true,
        separator: true,
        source: "live version",
        valueField: "id",
      });
      setDropDownQuotesFilter({
        id: "DropDownQuotes",
        version: "default",
        placeholder: "Select quotes to display",
        selectAll: false,
      });
      setResourcesFilter({
        id: "Resources",
        version: "default",
        backgroundColor: "#ffffff",
        borderColor: "#d0021b",
        borderWidth: 0,
        headerColor: "#000000",
        headerFontSize: 14,
        headerFontWeight: 400,
        headerPaddingBottom: 20,
        linbBrderColor: "#d0021b",
        lineBorderColor: "#9b9b9b",
        lineBorderWidth: 1,
        paddingBottom: 0,
        paddingHorizontal: 30,
        paddingTop: 35,
        source: "live version",
        textColor: "#000000",
        textFontSize: 13,
        textFontWeight: 400,
        textPaddingBottom: 3,
        textPaddingTop: 3,
      });
      setLegendFilter({
        id: "Legend",
        version: "default",
        arrowHeadColor: "#000000",
        arrowHeadLength: 8,
        arrowHeadWidth: 3,
        arrowShaftColor: "#000000",
        arrowShaftLength: 30,
        arrowShaftOffset: 15,
        arrowShaftWidth: 4,
        backgroundColor: "#cabe2f",
        backgroundOpacity: 1,
        borderColor: "#d0021b",
        borderRadius: 10,
        borderWidth: 0,
        boxheight: 47,
        boxwidth: 58,
        directionColor: "#4d4d4f",
        directionFontSize: 18,
        highestBackground: "#000000",
        lowestBackground: "#000000",
        marginNoData: 36,
        noDataFontSize: 10,
        popupArrowOffset: 5,
        popupBackground: "#9b9b9b",
        popupColor: "#111111",
        popupFontSize: 12,
        popupFontWeight: 400,
        popupHeight: 65,
        popupLineHeight: 14,
        popupOffset: 9,
        popupPointerBox: 16,
        popupRadius: 12,
        popupValueColor: "#000000",
        popupValueFontSize: 14,
        popupValueFontWeight: 600,
        popupWidth: 176,
        selectionColor: "#000000",
        selectionFontSize: 22,
        source: "live version",
        transparentBackground: false,
        valueColorMax: "#ffffff",
        valueColorMin: "#000000",
        valueColorNoData: "#000000",
        valueFontSize: 13,
      });
      setLayoutFilter({
        id: "Layout",
        version: "default",
        backgroundColor: "#ffffff",
        backgroundOpacity: 2,
        detailFontColor: "#000000",
        detailFontSize: 20,
        detailFontWeight: 400,
        leftPanelMarginTop: -30,
        leftPanelPercentage: 70,
        logoHeight: 65,
        logoMarginBottom: 40,
        logoMarginLeft: -60,
        logoMarginRight: 0,
        logoMarginTop: -32,
        paddingBottom: 40,
        paddingHorizontal: 40,
        paddingTop: 40,
        rightPanelMarginTop: 0,
        source: "live version",
        tabBackgroundColor: "#ffffff",
        tabBackgroundColorBack: "#ffffff",
        tabBackgroundColorData: "#ffffff",
        tabBackgroundColorExplore: "#ffffff",
        tabBackgroundColorInsights: "#ffffff",
        tabBackgroundColorNavigation: "#ffffff",
        tabBackgroundColorSelected: "#14516e",
        tabBackgroundColorStories: "#ffffff",
        tabBorderColor: "#000000",
        tabBorderRadius: 14,
        tabBorderWidth: 0,
        tabFontColor: "#4d4d4f",
        tabFontColorData: "#4d4d4f",
        tabFontColorExplore: "#4d4d4f",
        tabFontColorInsights: "#4d4d4f",
        tabFontColorNavigation: "#4d4d4f",
        tabFontColorSelected: "#efbb2a",
        tabFontColorStories: "#4d4d4f",
        tabFontSize: 16,
        tabFontWeight: 500,
        tabGap: 5,
        tabMarginBottom: 12,
        tabMarginTop: -32,
        tabPadding: 4,
        tabWidth: 79,
        titleFontColor: "#000000",
        titleFontSize: 29,
        titleFontWeight: 600,
        transparentBackground: false,
      });
    };
    asyncfunction();
  }, []);

  useEffect(() => {
    if (mapRef.current && !mapbox) {
      setTimeout(() => {
        setMapbox(mapRef.current.getBoundingClientRect());
      }, 10);
    }
    if (dropdownRef.current && !dropdownbox) {
      setDropdownbox(dropdownRef.current.getBoundingClientRect());
    }
    if (rightRef.current && !rightbox) {
      setRightbox(rightRef.current.getBoundingClientRect());
    }
  }, [
    mapbox,
    rightbox,
    dropdownbox,
    dropdownfilter,
    dropdownquotesfilter,
    mapfilter,
    multifilter,
    cardQuotesFilter,
    cardStoriesFilter,
    cardDoubleFilter,
    layoutfilter,
    resourcesFilter,
    menuOptions,
  ]);

  useEffect(() => {
    if (selectValues && selectGValues) {
      if (!selectValues.map((item) => item.id).includes(selectGValues.label)) {
        setGValues(null);
      }
    } else {
      setGValues(null);
    }
  }, [selectValues, selectGValues]);

  useEffect(() => {
    if (selectGValues && mapfilter) {
      const d = Object.entries(constructedData.refData[selectGValues.label])
        .filter((item) => !isNaN(item[1][selectGValues.index]))
        .map((item) => +item[1][selectGValues.index]);

      const average = d.reduce((a, b) => a + b) / d.length;

      const e = d3.extent(d);

      const myColor = d3
        .scaleLinear()
        .domain(e)
        .range([mapfilter.mapGradientMin, mapfilter.mapGradientMax]);

      setDomain(e);
      setAverage(average);
      setColorFunction(() => myColor);
    }
  }, [constructedData.refData, selectGValues, mapfilter]);

  if (
    dropdownfilter &&
    dropdownquotesfilter &&
    mapfilter &&
    multifilter &&
    cardQuotesFilter &&
    cardStoriesFilter &&
    cardDoubleFilter &&
    cardTop5Filter &&
    layoutfilter &&
    resourcesFilter &&
    (selectGValues ? colorfunction : true)
  ) {
    const getGradient = (county) => {
      return selectGValues &&
        colorfunction &&
        menuOptions === "Data" &&
        constructedData.refData[selectGValues.label][county] &&
        constructedData.refData[selectGValues.label][county][0] !== "No Data"
        ? colorfunction(
            constructedData.refData[selectGValues.label][county][
              selectGValues.index
            ]
          )
        : menuOptions === "Data"
        ? mapfilter.mapBackground
        : mapfilter[constructedData.countyRegion[county]];
    };
    const getLabel = (county, isPopup) => {
      return menuOptions === "Data"
        ? county
        : isPopup
        ? constructedData.countyRegion[county]
        : countiesToUseForRegionLabels.includes(county)
        ? constructedData.countyRegion[county]
        : "";
    };

    const getColor = (mcounty) => {
      return county && mcounty === county
        ? mapfilter.labelColorSelected
        : mapfilter.labelColor;
    };
    const getFontSize = (mcounty) => {
      return county && mcounty === county
        ? mapfilter.labelFontSizeSelected
        : mapfilter.labelFontSize;
    };
    const getFontWeight = (mcounty) => {
      return county && mcounty === county
        ? mapfilter.labelFontWeightSelected
        : mapfilter.labelFontWeight;
    };

    const hexToRgb = (hex) => {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    };

    const rgbValues = Object.values(hexToRgb(layoutfilter.backgroundColor));
    rgbValues.push(layoutfilter.backgroundOpacity / 10);

    const getPanelWidthPercentage = (side, selection) => {
      switch (selection) {
        case "Welcome":
          return side === "l" ? "99%" : "1%";
        case "Resources":
          return side === "l" ? "99%" : "1%";
        case "Tools for Advocacy":
          return side === "l" ? "99%" : "1%";
        case "How to Navigate":
          return side === "l" ? "99%" : "1%";
        default:
          return side === "l"
            ? `${layoutfilter.leftPanelPercentage}%`
            : `${100 - layoutfilter.leftPanelPercentage}%`;
      }
    };

    return (
      <div
        style={{
          fontFamily: "Graphic",
          width: "100vw",
          height: "100vh",
          backgroundColor: layoutfilter.transparentBackground
            ? "transparent"
            : `rgba(${rgbValues.join(",")})`,
          paddingTop: 40,
          paddingLeft: 40,
          paddingRight: 40,
          paddingBottom: 40,
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* top */}
          <div
            className="top"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              opacity: 1.0,
              gap: 10,
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: "auto",
            }}
          >
            <div>
              <TabButtons
                filter={layoutfilter}
                list={menuOptionsArray}
                selected={menuOptions}
                setSelected={setMenuOptions}
              />
            </div>
            <div
              style={{
                height: layoutfilter.logoHeight,
                marginRight: layoutfilter.logoMarginRight,
                marginTop: layoutfilter.logoMarginTop,
                marginBottom: layoutfilter.logoMarginBottom,
                cursor: "pointer",
              }}
            >
              {menuOptions !== "Welcome" ? (
                <a href={"https://instituteforfamily.org/nexus-dashboard/"}>
                  <img
                    style={calculateAspectRatioFit(
                      6746,
                      2277,
                      3000,
                      layoutfilter.logoHeight
                    )}
                    alt="logo"
                    src={logo}
                  />
                </a>
              ) : null}
            </div>
          </div>
          {/* bottom */}
          <div
            style={{
              width: "100%",
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="bottom"
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
              }}
            >
              {/* left */}
              <div
                ref={dropdownRef}
                className="left"
                style={{
                  width: getPanelWidthPercentage("l", menuOptions),
                  flexBasis: "auto",
                  flexGrow: 1,
                  flexShrink: 1,
                  display: "flex",
                  flexDirection: "column",
                  marginTop: layoutfilter.leftPanelMarginTop,
                }}
              >
                {menuOptions === "Data" || menuOptions === "Insights" ? (
                  <div
                    className="dropdowndiv"
                    style={{
                      flexBasis: "auto",
                      flexGrow: 0,
                      flexShrink: 1,
                    }}
                  >
                    {menuOptions === "Data" ? (
                      <DropDownConfig
                        filter={dropdownfilter}
                        selectValues={selectValues}
                        setValues={setValues}
                        dropdownbox={dropdownbox}
                        options={options}
                      />
                    ) : menuOptions === "Insights" ? (
                      <DropDownConfig
                        filter={{
                          ...dropdownfilter,
                          selectAll: dropdownquotesfilter.selectAll,
                          placeholder: dropdownquotesfilter.placeholder,
                        }}
                        selectValues={selectQuotesValue}
                        setValues={setQuotesValue}
                        dropdownbox={dropdownbox}
                        options={regionOptions}
                      />
                    ) : null}
                  </div>
                ) : null}

                {/* mapdiv */}
                {menuOptions === "Insights" || menuOptions === "Data" ? (
                  <div
                    className="mapdiv"
                    style={{
                      flexBasis: "auto",
                      flexGrow: 1,
                      flexShrink: 1,
                    }}
                    ref={mapRef}
                  >
                    <div
                      style={{
                        fontFamily: "Graphic",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Graphic",
                          width: "100%",
                          height: "100%",
                        }}
                        className="mapdiv"
                      >
                        <MapChartConfig
                          filter={mapfilter}
                          setCounty={setCounty}
                          mapbox={mapbox}
                          getGradient={getGradient}
                          getLabel={getLabel}
                          getColor={getColor}
                          getFontSize={getFontSize}
                          getFontWeight={getFontWeight}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {menuOptions === "How to Navigate" ? (
                  <NavigationTipsConfig filter={navfilter} />
                ) : menuOptions === "Explore More" ? (
                  <ExploreConfig filter={exploreFilter} />
                ) : menuOptions === "Welcome" ? (
                  <WelcomeConfig filter={welcomefilter} />
                ) : null}
                {resourcesFilter && menuOptions === "Resources" ? (
                  <ResourcesConfig filter={resourcesFilter} />
                ) : null}
                {exploreFilter && menuOptions === "Tools for Advocacy" ? (
                  <ExploreConfig filter={exploreFilter} />
                ) : null}
                {storiesFilter && menuOptions === "Stories" ? (
                  <StoriesConfig filter={storiesFilter} />
                ) : null}
                {legendfilter && menuOptions === "Data" ? (
                  <div
                    className="legenddiv"
                    style={{
                      flexGrow: 0,
                      flexShrink: 1,
                      flexBasis: `${
                        legendfilter.boxheight + legendfilter.borderWidth * 2
                      }px`,
                    }}
                  >
                    {selectGValues ? (
                      <LegendConfig
                        filter={legendfilter}
                        mapFilter={mapfilter}
                        selectGValues={selectGValues}
                        domain={domain}
                        average={average}
                        cardData={constructedData.cardData}
                        refData={constructedData.refData}
                        configure={false}
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>
              {/* right */}
              <div
                ref={rightRef}
                className="right"
                style={{
                  width: getPanelWidthPercentage("r", menuOptions),
                  display: "flex",
                  flexDirection: "column",
                  marginTop: layoutfilter.rightPanelMarginTop,
                }}
              >
                <div style={{ flex: 6, width: "100%" }}>
                  <MultiPanelConfig
                    filter={multifilter}
                    quotesFilter={cardQuotesFilter}
                    doubleFilter={cardDoubleFilter}
                    top5Filter={cardTop5Filter}
                    storiesFilter={cardStoriesFilter}
                    county={
                      menuOptions === "Data"
                        ? county
                        : menuOptions === "Insights"
                        ? constructedData.countyRegion[county]
                        : ""
                    }
                    measures={
                      menuOptions === "Data"
                        ? selectValues && selectValues.length > 0
                          ? selectValues.map((item) => {
                              return { label: item.id };
                            })
                          : []
                        : menuOptions === "Insights" &&
                          selectQuotesValue &&
                          selectQuotesValue.length > 0
                        ? selectQuotesValue.map((item) => {
                            return { label: item.id };
                          })
                        : []
                    }
                    refData={constructedData.refData}
                    cardData={constructedData.cardData}
                    constructedData={constructedData}
                    setGValues={setGValues}
                    selectGValues={selectGValues}
                    rightbox={rightbox}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Loader />;
  }
};
