import React from "react";
import { NorthCarolinaChart } from "../../charts/northcarolina/NorthCarolinaChart";
import { calculateAspectRatioFit } from "../../utils/imagefunctions";

export const MapChartConfig = ({
  filter,
  setCounty,
  mapbox,
  getGradient,
  getLabel,
  getColor,
  getFontSize,
  getFontWeight,
}) => {
  if (mapbox && mapbox.width > 0) {
    const dim = calculateAspectRatioFit(1028, 390, mapbox.width, mapbox.height);

    return (
      <NorthCarolinaChart
        width={dim.width}
        height={mapbox.height}
        filter={{ ...filter, marginTop: (mapbox.height - dim.height) / 2 }}
        setCounty={setCounty}
        mapbox={mapbox}
        getGradient={getGradient}
        getLabel={getLabel}
        getColor={getColor}
        getFontSize={getFontSize}
        getFontWeight={getFontWeight}
      />
    );
   
  } else return null;
};
