import React, { useRef, useState, useEffect } from "react";
import { config as lconfig } from "./config";
import { options } from "../../pages/dropdown/options";

export const LegendConfig = ({
  filter,
  mapFilter,
  selectGValues,
  domain,
  average,
  cardData,
  refData,
  configure,
}) => {
  const popupRef = useRef(null);
  const [headerBox, setHeaderBox] = useState(null);
  const [showPopup, setShowPopup] = useState(configure ? true : false);

  useEffect(() => {
    if (!headerBox) {
      setTimeout(() => {
        setHeaderBox(popupRef.current.getBoundingClientRect());
      }, 10);
    }
  }, [headerBox]);

  if (selectGValues) {
    const config = lconfig(filter);
    const count = Object.keys(refData[selectGValues.label]).length;

    const headertext = options.find(
      (item) => item.id === selectGValues.label
    ).name;

    const format =
      selectGValues && cardData[selectGValues.label]
        ? cardData[selectGValues.label].format[selectGValues.index]
        : "";

    const boxstyle = {
      border: `${config.box.border.width}px solid ${config.box.border.color}`,
      borderRadius: config.box.border.radius,
      height: config.box.height,
      width: config.box.width,
    };

    const valuestyle = {
      fontSize: config.value.fontSize,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: "100%",
    };

    const nodatastyle = {
      fontSize: config.text.nodata.fontSize,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: "100%",
      color: config.text.nodata.color,
    };

    const textStyle = {
      fontSize: config.text.direction.fontSize,
      color: config.text.direction.color,
      display: "flex",
      alignItems: "center",
      padding: 10,
    };

    const selectedtextStyle = {
      fontSize: config.text.selection.fontSize,
      color: config.text.selection.color,
      display: "flex",
      alignItems: "center",
      padding: 10,
    };

    const arrowParent = {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 40,
      height: config.box.height,
    };

    const arrow = {
      width: config.arrow.head.length,
      height: config.arrow.head.length,
      borderBottom: `${config.arrow.head.width}px solid ${config.arrow.head.color}`,
      borderRight: `${config.arrow.head.width}px solid ${config.arrow.head.color}`,
    };

    const arrowline = {
      position: "absolute",
      width: config.arrow.shaft.length,
      top: config.box.height / 2 - config.arrow.shaft.width / 2,
      height: config.arrow.shaft.width,
      backgroundColor: config.arrow.shaft.color,
    };

    const popup = {
      position: "absolute",
      height: config.popup.height,
      width: config.popup.width,
      marginTop: -config.popup.height - config.box.height - config.popup.offset,
      left: headerBox
        ? headerBox.width / 2 + headerBox.x - config.popup.width / 2
        : 0,
      borderRadius: config.popup.radius,
      backgroundColor: config.popup.backgroundColor,
      boxSizing: "border-box",
    };
    const popupContent = {
      height: config.popup.height,
      width: config.popup.width,
      display: "table-cell",
      fontSize: `${config.popup.text.fontSize}px`,
      fontWeight: config.popup.text.fontWeight,
      color: config.popup.text.color,
      textAlign: "center",
      verticalAlign: "middle",
      lineHeight: `${config.popup.text.lineHeight / 10}em`,
    };
    const popupValue = {
      fontSize: `${config.popup.value.fontSize}px`,
      fontWeight: config.popup.value.fontWeight,
      color: config.popup.value.color,
    };

    const pointer = {
      position: "absolute",
      height: config.popup.arrow.size,
      width: config.popup.arrow.size,
      marginTop: -config.popup.height + config.popup.arrow.offset,
      left: headerBox
        ? headerBox.width / 2 + headerBox.x - config.popup.arrow.size / 2
        : 0,
      transform: "rotate(45deg)",
      backgroundColor: config.popup.backgroundColor,
    };

    const NoData = () => {
      return (
        <div
          style={{
            ...boxstyle,
            backgroundColor: mapFilter.mapBackground,
            marginLeft: config.margin.noData,
          }}
        >
          <div style={nodatastyle}>No Data</div>
        </div>
      );
    };

    const Min = () => {
      return (
        <div style={{ ...boxstyle, backgroundColor: mapFilter.mapGradientMin }}>
          <div style={{ ...valuestyle, color: config.value.colorMin }}>
            {format
              ? format === "percent"
                ? `${domain[0] ? (domain[0] * 100).toFixed(2) : ""}%`
                : format === "number"
                ? domain[0].toLocaleString()
                : format === "money"
                ? `$${domain[0].toLocaleString()}`
                : format === "grade"
                ? String.fromCharCode(domain[0])
                : domain[0]
              : domain[0]}
          </div>
        </div>
      );
    };

    const Avg = () => {
      return format
        ? format === "percent"
          ? `${average ? (average * 100).toFixed(2) : ""}%`
          : format === "number"
          ? average.toLocaleString()
          : format === "money"
          ? `$${average.toLocaleString()}`
          : format === "grade"
          ? String.fromCharCode(average)
          : average
        : average;
    };

    const Max = () => {
      return (
        <div style={{ ...boxstyle, backgroundColor: mapFilter.mapGradientMax }}>
          <div style={{ ...valuestyle, color: config.value.colorMax }}>
            {format
              ? format === "percent"
                ? `${domain[1] ? (domain[1] * 100).toFixed(2) : ""}%`
                : format === "number"
                ? domain[1].toLocaleString()
                : format === "money"
                ? `$${domain[1].toLocaleString()}`
                : format === "grade"
                ? String.fromCharCode(domain[1])
                : domain[1]
              : domain[1]}
          </div>
        </div>
      );
    };

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          height: config.box.height + config.box.border.width * 2,
        }}
        onMouseOver={() => setShowPopup(true)}
        onMouseOut={() => setShowPopup(configure ? true : false)}
      >
        {format === "grade" ? <Max /> : <Min />}
        <div style={arrowParent}>
          <div style={{ ...arrow, transform: "rotate(135deg)" }}></div>
          <div style={{ ...arrowline, left: config.arrow.shaft.offset }}></div>
        </div>
        <div style={textStyle}>Lowest</div>
        <div style={selectedtextStyle} ref={popupRef}>
          {headertext}
          {showPopup && (
            <>
              <div style={popup}>
                <div style={popupContent}>
                  Average for North Carolina <span style={popupValue}>{Avg()}</span>
                </div>
              </div>
              <div style={pointer}></div>
            </>
          )}
        </div>
        <div style={textStyle}>Highest</div>

        <div style={arrowParent}>
          <div style={{ ...arrow, transform: "rotate(-45deg)" }}></div>
          <div style={{ ...arrowline, right: config.arrow.shaft.offset }}></div>
        </div>
        {format === "grade" ? <Min /> : <Max />}
        {count !== 100 && <NoData />}
      </div>
    );
  } else return null;
};
