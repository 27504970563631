import { useRef, useEffect, useState } from "react";
import { config as storiesConfig } from "./config";
import consolidated from "../../images/stories.png";
import { calculateAspectRatioFit } from "../../utils/imagefunctions";
import StoriesClickable from "../../images/svg/StoriesClickable";

export const StoriesConfig = ({ filter }) => {
  const boxRef = useRef();
  // const [boxWidth, setBoxWidth] = useState();
  // const [boxHeight, setBoxHeight] = useState();
  const [dimensions, setDimensions] = useState(null);
  const [config, setConfig] = useState(null);

  // console.log("height", boxHeight, "width", boxWidth);

  useEffect(() => {
    if (filter) {
      setConfig(storiesConfig(filter));
    }
  }, [filter]);

  useEffect(() => {
    if (!boxRef.current || !config) return;
    const resizeObserver = new ResizeObserver(() => {
      const dim = boxRef.current.getBoundingClientRect();
      // setBoxWidth(dim.width);
      // setBoxHeight(dim.height);
      ////
      const ar = calculateAspectRatioFit(
        1516,
        852,
        (dim.width / 1193) * dim.width,
        (dim.height / 627) * dim.height -
          config.container.padding.top -
          config.container.padding.bottom
      );
      setDimensions(ar);
      ///////
    });
    resizeObserver.observe(boxRef.current);
    return () => resizeObserver.disconnect();
  }, [config]);

  useEffect(() => {
    if (config) {
      const svgMentalHealthClicked = () => {
        window.open(
          "https://www.youtube.com/watch?v=eu0XfRSVI-I&list=PL1B9zgitRZJ0H0waBXL5-CRw6-mBGzTIh&index=6",
          "_blank"
        );
      };
      const svgEducationClicked = () => {
        window.open(
          "https://www.youtube.com/watch?v=adKcZLFuDr8&list=PL1B9zgitRZJ0H0waBXL5-CRw6-mBGzTIh&index=11",
          "_blank"
        );
      };
      const svgFamilyClicked = () => {
        window.open(
          "https://www.youtube.com/watch?v=XfXuxgTDihA&list=PL1B9zgitRZJ0H0waBXL5-CRw6-mBGzTIh&index=1",
          "_blank"
        );
      };
      const svgCivicClicked = () => {
        window.open(
          "https://www.youtube.com/watch?v=bgmEfa3BQb8&list=PL1B9zgitRZJ0H0waBXL5-CRw6-mBGzTIh&index=2",
          "_blank"
        );
      };
      const svgChildProtectClicked = () => {
        window.open(
          "https://www.youtube.com/watch?v=CYgNmq5m0O0&list=PL1B9zgitRZJ0H0waBXL5-CRw6-mBGzTIh&index=10",
          "_blank"
        );
      };
      const svgHousingClicked = () => {
        window.open(
          "https://www.youtube.com/watch?v=73n_g3bvLIw&list=PL1B9zgitRZJ0H0waBXL5-CRw6-mBGzTIh&index=9",
          "_blank"
        );
      };
      var svgMentalHealth = document.getElementById("s-mental-health");
      svgMentalHealth.addEventListener("click", svgMentalHealthClicked);
      var svgEducation = document.getElementById("s-education");
      svgEducation.addEventListener("click", svgEducationClicked);
      var svgFamily = document.getElementById("s-family");
      svgFamily.addEventListener("click", svgFamilyClicked);
      var svgCivic = document.getElementById("s-civic");
      svgCivic.addEventListener("click", svgCivicClicked);
      var svgChildProtect = document.getElementById("s-childprotect");
      svgChildProtect.addEventListener("click", svgChildProtectClicked);
      var svgHousing = document.getElementById("s-housing");
      svgHousing.addEventListener("click", svgHousingClicked);
    }
  }, [config]);

  const containerstyle = {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    paddingTop: config ? config.container.padding.top : undefined,
    paddingBottom: config ? config.container.padding.bottom : undefined,
  };

  return config ? (
    <div style={containerstyle} ref={boxRef}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute" }}>
          <StoriesClickable
            width={dimensions ? dimensions.width : 100}
            height={dimensions ? dimensions.height : 100}
            fill="#ffffff"
          />
        </div>
        <div style={{ position: "absolute", pointerEvents: "none" }}>
          <img
            style={{
              width: dimensions ? dimensions.width : 100,
              height: dimensions ? dimensions.height : 100,
              border: `${config.image.border.width}px solid ${config.image.border.color}`,
            }}
            alt="consolidated"
            src={consolidated}
          />
        </div>
      </div>
    </div>
  ) : null;
};
