export const options = [
  {
    id: 1,
    name: "Work and Wages",
    indented: false,
  },
  {
    id: "Top 5 Employers",
    name: "Top 5 employers",
    indented: true,
    parentId: 1,
  },
  {
    id: "Median Family Income ",
    name: "Median Family Income",
    indented: true,
    parentId: 1,
  },
  {
    id: "Labor Force",
    name: "Parental Employment",
    indented: true,
    parentId: 1,
  },
  {
    id: 2,
    name: "Housing",
    indented: false,
  },
  {
    id: "Homeowners",
    name: "Home ownership",
    indented: true,
    parentId: 2,
  },
  {
    id: "House Funds",
    name: "House funds",
    indented: true,
    parentId: 2,
  },
  {
    id: 3,
    name: "Nutrition & Healthcare",
    indented: false,
  },
  {
    id: "Uninsured",
    name: "Uninsured",
    indented: true,
    parentId: 3,
  },
  {
    id: "Prenatal Care",
    name: "Prenatal Care",
    indented: true,
    parentId: 3,
  },
  {
    id: "Grocery stores",
    name: "Grocery stores",
    indented: true,
    parentId: 3,
  },
  {
    id: 4,
    name: "Education",
    indented: false,
  },
  {
    id: "Avg grade of schools",
    name: "Average Grade of Public Schools",
    indented: true,
    parentId: 4,
  },
  {
    id: "% of schools in the county that didn’t meet growth status",
    name: "% of schools that didn’t meet growth",
    indented: true,
    parentId: 4,
  },
  {
    id: 5,
    name: "Civic Engagement",
    indented: false,
  },
  {
    id: "Voting Turnout rate",
    name: "Voting Turnout rate",
    indented: true,
    parentId: 5,
  },
  {
    id: "Voting Facilities",
    name: "Voting Facilities",
    indented: true,
    parentId: 5,
  },
  {
    id: 6,
    name: "A Child Protective Environment",
    indented: false,
  },
  {
    id: "Children below 100% pov line",
    name: "Children below 100% poverty line",
    indented: true,
    parentId: 6,
  },
  {
    id: "Child abuse and neglect",
    name: "Child abuse and neglect",
    indented: true,
    parentId: 6,
  },
  {
    id: "Children in foster care",
    name: "Children in Foster Care",
    indented: true,
    parentId: 6,
  },
  {
    id: "Child Care Facilities",
    name: "Child Care Facilities",
    indented: true,
    parentId: 6,
  },
];

export const regionOptions = [
  {
    id: "What is going well for your family right now?",
    name: "What is going well for your family right now?",
    indented: true,
  },
  {
    id: "What are the challenges that your family is facing right now?",
    name: "What are the challenges that your family is facing right now?",
    indented: true,
  },
  {
    id: "If there could be one change in your community that would help your family to thrive, what would you want to see?",
    name: "If there could be one change in your community that would help your family to thrive, what would you want to see?",
    indented: true,
  },
];
