export const config = (filter) => {
  return {
    layout: {
      margin: {
        top: filter.marginTop,
        right: filter.margin,
        bottom: 0,
        left: filter.margin,
      },
    },
    originalSvgDimensions: { width: 810, height: 316 },
    svg: {
      background: filter.mapTransparentBackground
        ? "transparent"
        : filter.svgBackgroundColor,
      border: `${filter.svgBorderWidth}px solid ${filter.svgBorderColor}`,
    },
    map: {
      background: filter.mapBackground,
      stroke: filter.mapStroke,
      strokeWidth: filter.mapStrokeWidth,
    },
    tooltips: {
      active: filter.tooltips,
      text: {
        fontSize: filter.tooltipsFontSize,
        fontWeight: filter.tooltipsFontWeight,
        color: filter.tooltipsTextColor,
      },
      box: {
        width: 250,
        height: 100,
        fill: filter.tooltipsBackgroundColor,
        stroke: filter.tooltipsBorderColor,
        strokeWidth: filter.tooltipsBorderWidth,
        radius: filter.tooltipsBorderRadius,
        gap: filter.tooltipsGap,
        verticalPadding: filter.tooltipsVerticalPadding,
        horizontalPadding: filter.tooltipsHorizontalPadding,
      },
    },
    labels: {
      active: filter.labels,
      fontSize: filter.labelFontSize,
      color: filter.labelColor,
      fontWeight: filter.labelFontWeight,
    },
    labelsSelected: {
      fontSize: filter.labelFontSizeSelected,
      color: filter.labelColorSelected,
      fontWeight: filter.labelFontWeightSelected,
    },
  };
};
