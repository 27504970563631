export const config = (filter) => {
  return {
    title: {
      fontSize: filter.titleFontSize,
      color: filter.titleTextColor,
      fontWeight: filter.titleFontWeight,
      paddingTop: filter.titlePaddingTop,
      paddingBottom: filter.titlePaddingBottom,
      paddingHorizontal: filter.titlePaddingHorizontal,
    },
    expanded: {
      fontSize: filter.expandedFontSize,
      color: {
        question: filter.questionTextColor,
        answer: filter.answerTextColor,
      },
      selectedColor: filter.expandedSelectedColor,
      fontWeight: filter.expandedFontWeight,
      paddingTop: filter.expandedPaddingTop,
      paddingBottom: filter.expandedPaddingBottom,
      paddingHorizontal: filter.expandedPaddingHorizontal,
    },
    value: {
      fontSize: filter.valueFontSize,
      color: filter.valueTextColor,
      selectedColor: filter.valueSelectedColor,
      fontWeight: filter.valueFontWeight,
      paddingTop: filter.valuePaddingTop,
      paddingBottom: filter.valuePaddingBottom,
      paddingHorizontal: filter.valuePaddingHorizontal,
      lineHeight:filter.valueLineHeight
    },
    footer: {
      value: {
        width: filter.footerWidth,
        height: filter.footerHeight,
        color: filter.footerColor,
        paddingTop: filter.footerPaddingTop,
        paddingBottom: filter.footerPaddingBottom,
      },
      expanded: {
        width: filter.footerExpandedWidth,
        height: filter.footerExpandedHeight,
        color: filter.footerExpandedColor,
        paddingTop: filter.footerExpandedPaddingTop,
        paddingBottom: filter.footerExpandedPaddingBottom,
      },
      divider: {
        width: filter.dividerWidth,
        height: filter.dividerHeight,
        color: filter.dividerColor,
        paddingTop: filter.dividerPaddingTop,
        paddingBottom: filter.dividerPaddingBottom,
      },
    },
  };
};
