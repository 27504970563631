import * as d3 from "d3";
import { config as MapConfig } from "./config";
// import nc from "../../data/nc_counties.json";
import nc500 from "../../data/nc500counties.json";

export function draw(
  container,
  filter,
  setCounty,
  mapbox,
  getGradient,
  getLabel,
  getColor,
  getFontSize,
  getFontWeight
) {
  const cwidth = container.clientWidth;
  // const cheight = container.clientHeight;
  const config = MapConfig(filter);

  const {
    labels,
    layout,
    map,
    svg: svgx,
    originalSvgDimensions,
    tooltips,
  } = config;

  const svg = d3.select(container);
  svg.selectAll("g").remove();

  /** d3 margin convention */
  const width = cwidth; //- layout.margin.left - layout.margin.right;
  // const height = cheight - layout.margin.top - layout.margin.bottom;
  /** SVG Styles */
  svg.style("background-color", svgx.background);
  if (svgx.border) {
    svg.style("border", svgx.border);
  }

  const layoutgroup = svg.append("g")
  .attr(
    "transform",
    "translate(" + layout.margin.left + "," + layout.margin.top + ")"
  );

  const onMouseover = (x, y, d) => {
    tooltipgroup.style("display", "block");

    tooltipgroup.select(".countyname").html(getLabel(d.id, true));

    const pdiv = tooltipgroup.select(".parentdiv").node();
    const pdivwidth = pdiv.getBoundingClientRect().width;

    const xw = tooltips.box.width - pdivwidth;
    const tx = x - mapbox.left - tooltips.box.width / 2;
    const ctx = x - mapbox.left - pdivwidth / 2;
    const ty = y - mapbox.top - tooltips.box.gap - layout.margin.top;

    const ctp = tx + xw / 2;

    const allowedwidth = width - pdivwidth;
    const xdiff =
      ctp < 0
        ? ctp * -1 - layout.margin.left / 2
        : ctx > allowedwidth
        ? allowedwidth - tx - xw / 2 + layout.margin.right / 2
        : 0;

    tooltipgroup.attr(
      "transform",
      "translate(" + (tx + xdiff) + "," + ty + ")"
    );
  };

  //const scalefactor = 1;
  const scalefactor = width / originalSvgDimensions.width;

  const countygroups = layoutgroup
    .selectAll(".countygroup")
    .data(nc500)
    .enter()
    .append("g")
    .attr("class", "countygroup");

  countygroups
    .append("path")
    .attr("class", "county")
    .attr("d", (d) => {
      return d.d;
    })
    .style("fill", (d) => getGradient(d.id))
    .style("stroke", map.stroke)
    .style("stroke-width", map.strokeWidth / scalefactor)
    .attr("transform", `scale(${scalefactor})`)
    .on("click", (e, d) => {
      setCounty(d.id);
    })
    .on("mouseover", function (e, d) {
      if (tooltips.active) {
        const x = e.x;
        const y = e.y;
        onMouseover(x, y, d);
      }
    })
    .on("mouseout", function (e) {
      tooltipgroup.style("display", "none");
    });

  const labelPositions = {};

  d3.selectAll(".countygroup").each(function (d, i) {
    var bounds = this.getBBox();
    labelPositions[d.id] = {
      x: bounds.x + bounds.width / 2,
      y: bounds.y + bounds.height / 2,
    };
  });
  if (labels.active) {
    layoutgroup
      .selectAll(".label")
      .data(nc500)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("x", (d) => {
        return labelPositions[d.id].x;
      })
      .attr("y", (d) => {
        return labelPositions[d.id].y;
      })
      .attr("text-anchor", "middle")
      .style("font-size", (d) => `${getFontSize(d.id)}px`)
      .style("font-weight", (d) => getFontWeight(d.id))
      .style("fill", (d) => getColor(d.id))
      .style("pointer-events", "none")
      .text((d) => getLabel(d.id))
      .attr(
        "transform",
        (d) =>
          ` translate(${d.translateX ? d.translateX * scalefactor : 0},${
            d.translateY ? d.translateY * scalefactor : 0
          }) rotate(${d.rotate ? d.rotate : 0},${labelPositions[d.id].x},${
            labelPositions[d.id].y
          })`
      );
  }

  const tooltipgroup = layoutgroup.append("g").style("display", "none");

  // const tooltiparrow = layoutgroup.append("g").style("display", "none");
  const foreignObject = tooltipgroup
    .append("foreignObject")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", tooltips.box.width)
    .attr("height", tooltips.box.height)
    .style("pointer-events", "none");

  const aparentdiv = foreignObject
    .append("xhtml:div")
    // .style("width", "100%")
    // .style("height", "100%")
    .style("display", "flex")
    .style("justify-content", "center");

  const parentdiv = aparentdiv
    .append("xhtml:div")
    .attr("class", "parentdiv")
    // .style("height", "100%")
    .style("display", "inline-block")
    .style("justify-content", "center")
    .style("background-color", tooltips.box.fill)
    .style(
      "border",
      `${tooltips.box.strokeWidth}px solid ${tooltips.box.stroke}`
    )
    .style("border-radius", tooltips.box.radius + "px");

  const div = parentdiv.append("xhtml:div").style("display", "flex");
  // .style("height", tooltips.box.height + "px");

  const leftdiv = div
    .append("xhtml:div")
    .style("display", "flex")
    .attr("class", "leftdiv")
    .style("padding-left", `${tooltips.box.horizontalPadding}px`)
    .style("padding-right", `${tooltips.box.horizontalPadding}px`)
    .style("padding-top", `${tooltips.box.verticalPadding}px`)
    .style("padding-bottom", `${tooltips.box.verticalPadding}px`);
  leftdiv
    .append("xhtml:div")
    .attr("class", "countyname")
    .style("display", "flex")
    .style("align-items", "center")
    .style("justify-content", "center")
    .style("font-size", tooltips.text.fontSize + "px")
    .style("font-weight", tooltips.text.fontWeight)
    .style("color", tooltips.text.color)
    .html("");
}
