import React from "react";
import { config as DoubleConfig } from "./double/config";

export const Double = ({
  filter,
  county,
  measure,
  constructedData,
  setGValues,
  selectGValues,
}) => {
  const config = DoubleConfig(filter);

  const title =
    measure && measure.label && constructedData.cardData[measure.label]
      ? constructedData.cardData[measure.label].title
      : [];

  const format =
    measure && measure.label && constructedData.cardData[measure.label]
      ? constructedData.cardData[measure.label].format
      : [];

  const value =
    measure && measure.label
      ? constructedData.refData[measure.label]
        ? constructedData.refData[measure.label][county]
        : [null, null]
      : [null, null];

  const headertitle =
    measure && measure.label && constructedData.cardData[measure.label]
      ? constructedData.cardData[measure.label].headertitle
      : "";

  const subtitle =
    measure && measure.label && constructedData.cardData[measure.label]
      ? constructedData.cardData[measure.label].subtitle
      : "";

  const style = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const headertitlestyle = {
    fontSize: config.headertitle.fontSize,
    fontWeight: config.headertitle.fontWeight,
    color: config.headertitle.color,
    paddingTop: config.headertitle.paddingTop,
    paddingBottom: config.headertitle.paddingBottom,
    paddingLeft: config.headertitle.paddingHorizontal,
    paddingRight: config.headertitle.paddingHorizontal,
    textAlign: "center",
  };
  const subtitlestyle = {
    fontSize: config.subtitle.fontSize,
    fontWeight: config.subtitle.fontWeight,
    color: config.subtitle.color,
    paddingTop: config.subtitle.paddingTop,
    paddingBottom: config.subtitle.paddingBottom,
    paddingLeft: config.subtitle.paddingHorizontal,
    paddingRight: config.subtitle.paddingHorizontal,
    textAlign: "center",
  };

  const titlestyle = {
    fontSize: config.title.fontSize,
    fontWeight: config.title.fontWeight,
    color: config.title.color,
    paddingTop: config.title.paddingTop,
    paddingBottom: config.title.paddingBottom,
    paddingLeft: config.title.paddingHorizontal,
    paddingRight: config.title.paddingHorizontal,
    textAlign: "center",
  };

  const valuestyle = {
    fontSize: config.value.fontSize,
    fontWeight: config.value.fontWeight,
    paddingTop: config.value.paddingTop,
    paddingBottom: config.value.paddingBottom,
    paddingLeft: config.value.paddingHorizontal,
    paddingRight: config.value.paddingHorizontal,
    textAlign: "center",
  };

  const footerstyle = {
    borderBottom: `${config.footer.height}px solid ${config.footer.color}`,
    width: config.footer.width,
    paddingTop: config.footer.padding.top,
  };

  return county && value ? (
    <div style={style}>
      {headertitle && <div style={headertitlestyle}>{headertitle}</div>}
      {subtitle && <div style={subtitlestyle}>{subtitle}</div>}
      {value.map((item, i) => {
        return (
          <div key={i}>
            {title[i] && <div style={titlestyle}>{title[i]}</div>}
            <div>
              <div
                onClick={() => setGValues({ label: measure.label, index: i })}
                style={{
                  ...valuestyle,
                  color:
                    selectGValues &&
                    selectGValues.label === measure.label &&
                    selectGValues.index === i
                      ? config.value.selectedColor
                      : config.value.color,
                }}
              >
                {format
                  ? format[i] === "percent"
                    ? `${value ? (value[i] * 100).toFixed(2) : ""}%`
                    : format[i] === "number"
                    ? value[i].toLocaleString()
                    : format[i] === "money"
                    ? `$${value[i].toLocaleString()}`
                    : format[i] === "grade"
                    ? String.fromCharCode(value[i])
                    : value[i]
                  : value[i]}
              </div>
            </div>
          </div>
        );
      })}
      <div style={footerstyle}></div>
    </div>
  ) : null;
};
