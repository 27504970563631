import * as React from "react";
const Welcome2 = (props) => (
  <svg
    id="svg1"
    width={84}
    height={68.800003}
    viewBox="0 0 84 68.800003"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs id="defs1" />
    <g id="g1">
      <path
        id="path1"
        d="m 31.646357,41.427963 a 6.4936681,6.2470732 0 0 1 -6.493668,6.247073 6.4936681,6.2470732 0 0 1 -6.493668,-6.247073 6.4936681,6.2470732 0 0 1 6.493668,-6.247073 6.4936681,6.2470732 0 0 1 6.493668,6.247073"
      />
      <path
        id="path2"
        d="m 66.580644,41.510159 a 6.4114695,6.4114699 0 0 1 -6.41147,6.411469 6.4114695,6.4114699 0 0 1 -6.411469,-6.411469 6.4114695,6.4114699 0 0 1 6.411469,-6.41147 6.4114695,6.4114699 0 0 1 6.41147,6.41147 z"
      />
      <path
        d="m 33.536919,65.183276 c 0.136997,-3.397531 0.273995,-6.795062 0.246593,-8.795227 -0.0274,-2.000165 -0.219194,-2.602941 -0.438392,-3.164635 -0.219198,-0.561694 -0.465788,-1.082273 -0.753485,-1.575467 -0.287698,-0.493195 -0.616484,-0.958976 -0.945281,-1.342571 -0.328797,-0.383595 -0.657584,-0.684983 -1.05488,-0.94528 -0.397296,-0.260297 -0.863078,-0.479488 -1.260373,-0.684986 -0.397295,-0.205498 -0.726082,-0.39729 -1.178178,-0.561688 -0.452097,-0.164398 -1.027474,-0.301393 -1.685068,-0.438391 -0.657594,-0.136999 -1.397364,-0.273994 -2.123456,-0.315092 -0.726093,-0.0411 -1.438464,0.0137 -2.123457,0.191798 -0.684992,0.1781 -1.342566,0.479488 -1.917959,0.739785 -0.575393,0.260296 -1.068574,0.479488 -1.534369,0.821985 -0.465794,0.342498 -0.904177,0.808279 -1.301472,1.260375 -0.397296,0.452095 -0.753481,0.890477 -1.041178,1.315172 -0.287696,0.424696 -0.506888,0.83568 -0.643885,1.246675 -0.136998,0.410996 -0.191795,0.821979 -0.219195,1.219275 -0.0274,0.397296 -0.0274,0.780881 -0.0411,2.602976 -0.0137,1.822096 -0.0411,5.082564 -0.0685,8.343098 z"
        id="path3"
      />
      <path
        d="m 68.306813,65.183276 c 0.219196,-3.287933 0.438391,-6.575866 0.493189,-8.507532 0.0548,-1.931667 -0.0548,-2.507044 -0.273997,-3.109838 -0.219199,-0.602794 -0.547986,-1.232969 -0.917882,-1.835762 -0.369897,-0.602794 -0.78088,-1.17817 -1.178176,-1.602864 -0.397295,-0.424695 -0.78088,-0.698684 -1.150776,-0.972681 -0.369896,-0.273997 -0.726082,-0.547986 -1.095978,-0.739783 -0.369896,-0.191797 -0.753481,-0.301393 -1.164477,-0.410992 -0.410996,-0.109599 -0.849378,-0.219194 -1.452173,-0.315093 -0.602796,-0.0959 -1.369965,-0.178096 -2.027558,-0.178095 -0.657592,1e-6 -1.205569,0.0822 -1.698764,0.150697 -0.493194,0.0685 -0.931577,0.123297 -1.383672,0.232896 -0.452095,0.1096 -0.917877,0.273993 -1.411072,0.547992 -0.493195,0.273998 -1.013774,0.657582 -1.465869,1.027478 -0.452094,0.369896 -0.835679,0.726082 -1.137075,1.082278 -0.301396,0.356196 -0.520587,0.712382 -0.739785,1.068579 -0.219198,0.356196 -0.438389,0.712381 -0.589086,1.082278 -0.150698,0.369896 -0.232895,0.753481 -0.315094,1.150777 -0.0822,0.397296 -0.164396,0.808279 -0.191795,2.671475 -0.0274,1.863195 1e-6,5.178461 0.0274,8.493794 z"
        id="path4"
      />
      <path
        d="m 32.797134,34.358902 c 0,0 7.39785,-13.480526 7.39785,-13.480526 0,0 15.617683,0.904182 15.617683,0.904182 0.410992,-0.0822 0.821983,-0.164397 1.219279,-0.315096 0.397296,-0.150699 0.78088,-0.36989 1.068576,-0.630188 0.287696,-0.260298 0.479489,-0.561686 0.589087,-0.931583 0.109598,-0.369896 0.136998,-0.808279 0.260298,-2.712574 0.1233,-1.904295 0.342491,-5.274359 0.424689,-7.178625 0.0822,-1.9042654 0.0274,-2.3426478 -0.136999,-2.7262433 C 59.073197,6.9046531 58.799208,6.5758663 58.511511,6.2881698 58.223814,6.0004733 57.922426,5.7538832 57.703229,5.6168858 57.484032,5.4798883 57.347038,5.4524896 51.551941,5.7675898 45.756844,6.08269 34.304105,6.7402636 28.440621,7.0827568 c -5.863483,0.3424933 -6.137472,0.3698921 -6.356669,0.4657918 -0.219197,0.0959 -0.383591,0.2602933 -0.575389,0.5342914 -0.191798,0.2739981 -0.410989,0.6575825 -0.753487,1.8631782 -0.342498,1.2055958 -0.808279,3.2331138 -1.054875,4.4249928 -0.246596,1.19188 -0.273995,1.548066 -0.287695,1.849462 -0.0137,0.301396 -0.0137,0.547987 0.0137,0.821984 0.0274,0.273997 0.0822,0.575385 0.164397,0.863082 0.0822,0.287697 0.191795,0.561686 0.315094,0.794583 0.123299,0.232897 0.260294,0.42469 0.561692,0.561688 0.301399,0.136998 0.76718,0.219195 3.027674,0.315094 2.260495,0.0959 6.315531,0.205494 10.370649,0.315092 z"
        id="path5"
      />
      <path
        d="m 26.550061,19.809798 c 0,1.47957 0,2.95914 0,3.822225 0,0.863085 0,1.109675 0.0548,1.383673 0.0548,0.273997 0.164395,0.575384 0.260294,0.835681 0.0959,0.260297 0.178096,0.479488 0.301395,0.671286 0.123299,0.191798 0.287692,0.356192 0.424691,0.54799 0.136998,0.191798 0.246594,0.410989 0.410993,0.575387 0.164398,0.164398 0.38359,0.273993 0.547987,0.356192 0.164398,0.0822 0.273994,0.136997 0.958992,0.136996 0.684999,0 1.945348,-0.0548 3.205723,-0.109597"
        id="path6"
      />
      <path
        d="m 36.660456,27.947433 c 3.534528,0.0274 7.069056,0.0548 10.603584,0.0822 0,0 4.274314,7.069056 4.274314,7.069056 0,0 0.98638,-6.986858 0.98638,-6.986858 5.068897,0.273995 10.137794,0.547989 12.781842,0.684987 2.644049,0.136997 2.86324,0.136997 3.164637,0.123297 0.301398,-0.0137 0.684983,-0.0411 0.986379,-0.1233 0.301396,-0.0822 0.520587,-0.219194 0.794585,-0.493192 0.273998,-0.273999 0.602785,-0.684982 0.835681,-1.013778 0.232897,-0.328796 0.369891,-0.575386 0.47949,-0.863084 0.109598,-0.287697 0.191795,-0.616484 0.246594,-1.904281 0.0548,-1.287796 0.0822,-3.534505 -10e-7,-4.931882 -0.0822,-1.397377 -0.273993,-1.945355 -0.506891,-2.45225 -0.232898,-0.506894 -0.506887,-0.972675 -0.780884,-1.315171 -0.273997,-0.342495 -0.547986,-0.561686 -0.986383,-0.739784 -0.438397,-0.178097 -1.041173,-0.315092 -2.753667,-0.41099 -1.712493,-0.0959 -4.534579,-0.150697 -7.356722,-0.205496"
        id="path7"
      />
    </g>
  </svg>
);
export default Welcome2;
