export const config = (filter) => {
  return {
    parent: {
      dimensions: { width: filter.parentWidth, height: filter.parentHeight },
      background: filter.parentBackground,
      text: { fontSize: filter.parentFontSize, color: filter.parentTextColor },
      border: {
        radius: filter.parentRadius,
        width: filter.parentBorderWidth,
        color: filter.parentBorderColor,
      },
      padding: {
        top: filter.parentPaddingTop,
        left: filter.parentPaddingLeft,
        right: filter.parentPaddingRight,
        bottom: filter.parentPaddingBottom,
      },
    },
    inner: {
      background: filter.innerBackground,
      text: {
        fontSize: filter.innerFontSize,
        color: filter.innerTextColor,
        fontWeight: filter.innerFontWeight,
      },
      border: {
        radius: filter.innerRadius,
        width: filter.innerBorderWidth,
        color: filter.innerBorderColor,
      },
    },
  };
};
