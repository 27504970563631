export const config = (filter) => {
  return {
    content: [
      {
        step: "01",
        color: filter.step1Color,
        header: "SELECT DATA CATEGORIES",
        text: "First, click on the “data” tab in the top left corner. Next, click the dropdown menu to review and select data categories you’d like to review. You may select as many as you like.",
      },
      {
        step: "02",
        color: filter.step2Color,
        header: "SELECT A COUNTY TO REVIEW",
        text: "Then, select a county you would like to see data on. You can only select one county at a time. The right panel will display results. Don’t forget to scroll!",
      },
      {
        step: "03",
        color: filter.step3Color,
        header: "INTERACT WITH THE DATA THROUGH HEATMAPS",
        text: "The data points in the right panel are clickable. Select any data point to reveal a heat map. This map shows comparable data across the state.",
      },
      {
        step: "04",
        color: filter.step8Color,
        header: "HOVER TO COMPARE TO NC AVERAGES",
        text: "To see where each county stands in comparison to North Carolina averages, hover over the heat map indicator in the bottom left corner to see the average for the given data point.",
      },
      {
        step: "05",
        color: filter.step4Color,
        header: "REVIEW SURVEY INSIGHTS",
        text: "To see family perspectives and insights from state residents, click the “insights” tab in the top right of the webpage. These are direct quotes from the survey.",
      },
      {
        step: "06",
        color: filter.step5Color,
        header: "LEARN MORE ABOUT RESPONSES",
        text: "Click on an insight to see the respondent’s residing county and their other responses.",
      },
      {
        step: "07",
        color: filter.step6Color,
        header: "LISTEN TO STORIES FROM NORTH CAROLINA RESIDENTS",
        text: "The “stories” tab reveals the connection between child welfare data and family perspectives—the Shine on North Carolina storytelling campaign.",
      },
      // {
      //   step: "08",
      //   color: filter.step7Color,
      //   header: "FOR SMARTPHONE USERS",
      //   text: "The Nexus Dashboard is best experienced on a desktop or laptop computer. However, if you are using a smartphone, we recommend putting your display on the landscape orientation.",
      // },
     
    ],
    header: {
      text:"7 Tips",
      fontSize: filter.headerFontSize,
      fontWeight: filter.headerFontWeight,
      color: filter.headerColor,
    },
    subheader: {
      text:"On Navigating the Nexus Dashboard",
      fontSize: filter.subheaderFontSize,
      fontWeight: filter.subheaderFontWeight,
      color: filter.subheaderColor,
    },
    container: {
      padding: {
        left: filter.containerpaddingLeft,
        right: filter.containerpaddingRight,
        top: filter.containerpaddingTop,
        bottom: filter.containerpaddingBottom,
      },
      gradient1: filter.containerGradient1,
      gradient2: filter.containerGradient2,
      angle: filter.containerGradientAngle,
    },
    stepgroup: {
      height: filter.stepgroupHeight,
      width: filter.stepgroupWidth,
      backgroundColor: filter.stepgroupBackground,
      margin: filter.stepgroupMargin,
      padding: {
        left: filter.stepgroupInnerPaddingLeft,
        right: filter.stepgroupInnerPaddingRight,
        bottom: filter.stepgroupInnerPaddingBottom,
      },
    },
    step: {
      radius: filter.stepRadius,
      width: filter.stepWidth,
      padding: { left: filter.stepPaddingLeft },
      step: {
        fontSize: filter.stepFontSize,
        fontWeight: filter.stepFontWeight,
        color: filter.stepColor,
      },
      number: {
        fontSize: filter.stepNumberFontSize,
        fontWeight: filter.stepNumberFontWeight,
        color: filter.stepColor,
      },
      header: {
        fontSize: filter.stepHeaderFontSize,
        fontWeight: filter.stepHeaderFontWeight,
      },
      content: {
        fontSize: filter.stepContentFontSize,
        fontWeight: filter.stepContentFontWeight,
        color: filter.stepContentColor,
      },
    },
  };
};
