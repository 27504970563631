import * as React from "react";
const Welcome3 = (props) => (
  <svg
    id="svg1"
    width={84}
    height={68.800003}
    viewBox="0 0 84 68.800003"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs id="defs1" />
    <g id="g1">
      <path
        d="m 22.878536,8.4116292 c 9.480207,-0.054799 28.440622,-0.1643966 38.15373,-0.2054956 9.713107,-0.041099 10.178888,-0.0137 10.630984,0.0685 0.452095,0.0822 0.890477,0.2191942 1.219272,0.3698927 0.328796,0.1506986 0.547987,0.3150918 0.767185,0.4794903 0.219198,0.1643984 0.438389,0.3287918 0.712386,0.5753901 0.273998,0.2465983 0.602785,0.5753848 0.863081,0.9589818 0.260297,0.383597 0.45209,0.821979 0.561688,1.219274 0.109599,0.397296 0.136998,0.753482 0.150698,8.877575 0.0137,8.124094 0.0137,24.015454 -0.0274,32.139393 -0.0411,8.123938 -0.123297,8.480124 -0.287696,8.85002 -0.164399,0.369896 -0.410989,0.753481 -0.630186,1.054877 -0.219198,0.301396 -0.41099,0.520587 -0.616488,0.739785 -0.205498,0.219198 -0.424689,0.438389 -0.671287,0.575387 -0.246598,0.136998 -0.520587,0.191795 -0.849384,0.273995 -0.328797,0.0822 -0.712382,0.191795 -1.150778,0.273994 -0.438396,0.0822 -0.931576,0.136996 -10.795568,0.123296 -9.863992,-0.0137 -29.098017,-0.0959 -38.879621,-0.150697 -9.781605,-0.0548 -10.110392,-0.0822 -10.384388,-0.164399 -0.273997,-0.0822 -0.493188,-0.219195 -0.739786,-0.369893 C 10.65838,63.950296 10.384391,63.785903 10.151494,63.566704 9.9185968,63.347506 9.7268044,63.073517 9.5213064,62.78582 9.3158085,62.498123 9.0966173,62.196735 8.9185195,61.840538 8.7404217,61.484341 8.6034274,61.073358 8.4664287,60.634962 8.32943,60.196566 8.1924355,59.730785 8.192437,51.538193 8.1924384,43.3456 8.3294329,27.426841 8.4801315,19.289203 8.6308301,11.151564 8.7952233,10.795379 8.9870217,10.480282 9.17882,10.165186 9.3980112,9.8911969 9.6857089,9.6582997 9.9734066,9.4254025 10.329592,9.2336103 10.672089,9.0692122 11.014585,8.9048141 11.343372,8.7678196 11.589968,8.671921 c 0.246597,-0.095898 0.41099,-0.1506962 0.698689,-0.1780957 0.287698,-0.027399 0.698681,-0.027399 0.904177,-0.027399 0.205496,0 0.205494,2.3e-6 9.685702,-0.054797 z"
        id="path1"
      />
      <path
        d="m 18.672721,12.562645 c 3.055038,-0.0137 9.165114,-0.0411 12.425652,0 3.260538,0.0411 3.671521,0.150696 3.972917,0.315095 0.301396,0.164399 0.493188,0.38359 0.671286,0.643887 0.178098,0.260298 0.342491,0.561686 0.45209,0.876783 0.109598,0.315097 0.164396,0.643884 0.05479,1.00008 -0.1096,0.356197 -0.383589,0.739781 -0.643886,0.972678 -0.260298,0.232896 -0.506888,0.315093 -0.739785,0.356192 -0.232898,0.0411 -0.452089,0.0411 -3.685285,0.0274 -3.233197,-0.0137 -9.480146,-0.0411 -12.795483,-0.1233 -3.315336,-0.0822 -3.69892,-0.219194 -4.000316,-0.506893 -0.301396,-0.287698 -0.520588,-0.726081 -0.630185,-1.123377 -0.109598,-0.397295 -0.109598,-0.75348 0.0137,-1.095977 0.1233,-0.342496 0.36989,-0.671283 0.698688,-0.890479 0.328797,-0.219197 0.73978,-0.328792 0.945276,-0.383591 0.205496,-0.0548 0.205496,-0.0548 3.260534,-0.0685 z"
        id="path2"
      />
      <path
        d="M 75.786861,21.289368 C 53.319317,21.20717 30.851774,21.124971 8.3842298,21.042773"
        id="path3"
      />
      <path
        d="m 46.346159,12.507846 c -0.178096,0.0959 -0.534289,0.287694 -0.780886,0.520592 -0.246596,0.232898 -0.38359,0.506887 -0.479489,0.767184 -0.0959,0.260298 -0.150696,0.506888 -0.178096,0.780885 -0.0274,0.273997 -0.0274,0.575385 0.0685,0.876782 0.0959,0.301397 0.287692,0.602785 0.47949,0.863082 0.191798,0.260297 0.38359,0.479488 0.589088,0.643886 0.205498,0.164398 0.424689,0.273993 0.684987,0.356192 0.260298,0.0822 0.561686,0.136997 0.835683,0.164396 0.273997,0.0274 0.520587,0.0274 0.767184,-0.0137 0.246598,-0.0411 0.493188,-0.123297 0.712386,-0.287696 0.219197,-0.164399 0.410989,-0.410989 0.602787,-0.684987 0.191798,-0.273997 0.38359,-0.575385 0.465788,-0.917882 0.0822,-0.342497 0.0548,-0.726082 0.0137,-1.054878 -0.0411,-0.328796 -0.0959,-0.602785 -0.191797,-0.863082 -0.0959,-0.260297 -0.232894,-0.506887 -0.369893,-0.684984 -0.136998,-0.178098 -0.273993,-0.287694 -0.465792,-0.342492 -0.191798,-0.0548 -0.438388,-0.0548 -0.726086,-0.0959 -0.287697,-0.0411 -0.616484,-0.123297 -0.931581,-0.150696 -0.315096,-0.0274 -0.616484,1e-6 -0.767181,0.0137 -0.150697,0.0137 -0.150695,0.0137 -0.328791,0.109596 z"
        id="path4"
      />
      <path
        d="m 55.374276,13.836719 c -0.0274,0.191796 -0.0822,0.575388 -0.109598,0.863085 -0.0274,0.287696 -0.0274,0.479488 0.0137,0.684986 0.0411,0.205498 0.123297,0.424689 0.260296,0.630187 0.136999,0.205497 0.328792,0.39729 0.50689,0.561688 0.178098,0.164398 0.342491,0.301392 0.506889,0.424691 0.164399,0.123299 0.328792,0.232894 0.54799,0.315093 0.219199,0.0822 0.493188,0.136997 0.794585,0.164397 0.301397,0.0274 0.630184,0.0274 0.90418,-0.0685 0.273997,-0.0959 0.493188,-0.287693 0.684986,-0.520591 0.191798,-0.232898 0.356191,-0.506887 0.50689,-0.794584 0.150698,-0.287697 0.287692,-0.589085 0.35619,-0.904182 0.0685,-0.315097 0.0685,-0.643883 -10e-7,-0.97268 -0.0685,-0.328797 -0.205494,-0.657583 -0.383592,-0.94528 -0.178099,-0.287697 -0.39729,-0.534287 -0.780888,-0.712384 -0.383598,-0.178098 -0.931576,-0.287693 -1.30147,-0.328792 -0.369895,-0.0411 -0.561687,-0.0137 -0.780885,0.0959 -0.219198,0.1096 -0.465788,0.301393 -0.684986,0.452091 -0.219197,0.150698 -0.410989,0.260293 -0.575387,0.397292 -0.164398,0.136999 -0.301393,0.301392 -0.369891,0.383591 -0.0685,0.0822 -0.0685,0.0822 -0.0959,0.273992 z"
        id="path5"
      />
      <path
        d="m 65.97786,13.001036 c -0.109597,0.178096 -0.328793,0.534289 -0.465791,0.863085 -0.136998,0.328797 -0.191795,0.630185 -0.178095,0.931581 0.0137,0.301397 0.0959,0.602785 0.246597,0.917882 0.150699,0.315097 0.36989,0.643884 0.630187,0.904181 0.260298,0.260296 0.561686,0.452088 0.863083,0.561687 0.301397,0.109599 0.602785,0.136998 0.917882,0.123298 0.315097,-0.0137 0.643883,-0.0685 0.98638,-0.232897 0.342497,-0.1644 0.698682,-0.438389 0.972679,-0.808286 0.273996,-0.369897 0.465788,-0.835678 0.547986,-1.219273 0.0822,-0.383596 0.0548,-0.684984 -0.0137,-0.986381 -0.0685,-0.301396 -0.178095,-0.602784 -0.397294,-0.863081 -0.219199,-0.260297 -0.547986,-0.479488 -0.917882,-0.630186 -0.369897,-0.150698 -0.780881,-0.232895 -1.095976,-0.260294 -0.315096,-0.0274 -0.534287,10e-7 -0.753485,0.0274 -0.219198,0.0274 -0.438389,0.0548 -0.643887,0.137 -0.205497,0.0822 -0.39729,0.219194 -0.493188,0.287693 -0.0959,0.0685 -0.0959,0.06849 -0.205494,0.24659 z"
        id="path6"
      />
      <path
        d="m 31.892952,21.124971 c 0,14.466906 0,28.933813 0,43.400719"
        id="path7"
      />
      <path
        d="m 12.82294,45.209082 c 4.9045,0.0274 9.809,0.0548 14.713501,0.0822"
        id="path8"
      />
      <path
        d="m 12.905138,50.140982 c 4.849701,0.0274 9.699403,0.0548 14.549105,0.0822"
        id="path9"
      />
      <path
        d="m 12.740741,54.66189 c 4.849702,0.0274 9.699403,0.0548 14.549105,0.0822"
        id="path10"
      />
      <path
        d="m 12.987336,59.347195 c 4.767503,0 9.535007,0 14.30251,0"
        id="path11"
      />
      <path
        d="m 49.647792,26.878854 c 7.452649,0 14.905297,0 22.357946,0"
        id="path12"
      />
      <path
        d="m 49.647792,32.468341 c 7.39785,-0.0274 14.7957,-0.0548 22.193549,-0.0822"
        id="path13"
      />
      <path
        d="m 49.647792,38.140026 c 7.260852,-0.0548 14.521705,-0.109598 21.782558,-0.164397"
        id="path14"
      />
      <path
        d="m 38.633216,64.690087 c 0,-8.356831 0,-16.713661 0,-25.070491 0,0 5.425089,0.0822 5.425089,0.0822 0,0 -0.164396,24.741698 -0.164396,24.741698"
        id="path15"
      />
      <path
        d="m 47.26404,64.690087 c -0.0822,-6.43887 -0.164397,-12.877739 -0.246595,-19.316608 0,0 5.014099,0.0822 5.014099,0.0822 0,0 0.164396,19.316608 0.164396,19.316608"
        id="path16"
      />
      <path
        d="m 55.730468,64.443492 c -0.0822,-5.589487 -0.164397,-11.178973 -0.246595,-16.76846 0,0 5.260694,0.0822 5.260694,0.0822 0,5.644286 0,11.288571 0,16.932857"
        id="path17"
      />
      <path
        d="m 64.196897,64.607888 c -0.109598,-4.164715 -0.219196,-8.329431 -0.328794,-12.494146 0,0 5.096297,0 5.096297,0 -0.0274,4.192115 -0.0548,8.38423 -0.0822,12.576345"
        id="path18"
      />
      <path
        d="m 19.289208,26.467862 c -0.46579,0.136998 -1.123377,0.356193 -1.75357,0.643891 -0.630193,0.287698 -1.232969,0.643883 -1.712463,1.05488 -0.479494,0.410996 -0.835679,0.876777 -1.137076,1.287772 -0.301396,0.410996 -0.547986,0.767181 -0.780883,1.274078 -0.232898,0.506896 -0.452089,1.164469 -0.561687,1.780962 -0.109598,0.616494 -0.109598,1.191871 -0.0548,1.808365 0.0548,0.616494 0.164396,1.274067 0.342495,1.849461 0.178099,0.575393 0.424689,1.068573 0.794586,1.520668 0.369897,0.452095 0.863078,0.863078 1.411073,1.274074 0.547994,0.410996 1.15077,0.821979 1.808364,1.095975 0.657594,0.273996 1.369965,0.410991 2.068657,0.424689 0.698693,0.0137 1.383666,-0.0959 2.054959,-0.328796 0.671293,-0.232899 1.328866,-0.589084 1.904259,-0.958981 0.575394,-0.369896 1.068574,-0.753481 1.479569,-1.219277 0.410995,-0.465796 0.739781,-1.013773 1.054878,-1.602867 0.315097,-0.589095 0.616484,-1.21927 0.739781,-1.972763 0.123297,-0.753494 0.0685,-1.630258 -0.0685,-2.397449 -0.136999,-0.767191 -0.35619,-1.424765 -0.657588,-2.000158 -0.301398,-0.575393 -0.684983,-1.068573 -1.095979,-1.479568 -0.410996,-0.410995 -0.849378,-0.739782 -1.315174,-1.054878 -0.465795,-0.315097 -0.958975,-0.616485 -1.41107,-0.821982 -0.452095,-0.205497 -0.863078,-0.315092 -1.205574,-0.369891 -0.342496,-0.0548 -0.616485,-0.0548 -0.890482,-0.0274 -0.273997,0.0274 -0.547986,0.0822 -1.013777,0.219194 z"
        id="path19"
      />
      <path      
        d="m 20.220789,26.221268 c -0.0548,2.520748 -0.109597,5.041497 -0.164396,7.562246 0,0 7.069056,-0.164397 7.069056,-0.164397"
        id="path20"
      />
    </g>
  </svg>
);
export default Welcome3;
