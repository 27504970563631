import countydata from "../data/County Level Data for NC.json";
import infrastructure from "../data/Infrastructure--Grocery Stores by County in NC.json";
import households from "../data/TotalHouseholds.json";
import voting from "../data/Voting.json";
import votingturnout from "../data/VotingTurnout.json";
import votingreg from "../data/VotingReg.json";
import quotes from "../data/Quotes.json";
import questions from "../data/questions2.json";
import countyRegion from "../data/countyRegion.json";
import medianFamilyIncome from "../data/MedianFamilyIncome.json";

export const constructData = () => {
  const refData = {};
  const cardData = {};

  /****COUNTY***/
  Object.entries(countydata).forEach((entry, i) => {
    switch (entry[0]) {
      case "Labor Force":
        refData["Labor Force"] = {};
        entry[1].forEach((item) => {
          const county =
            item[
              "Children under age 18 with no parent in the labor force in NC"
            ];
          const value = [item["Column3"]];
          refData["Labor Force"][county] = value;
        });
        cardData["Labor Force"] = {
          card: "double",
          format: ["percent"],
          title: [""],
          headertitle:
            "Children under age 18 with no parent in the labor force.",
          subtitle: "2018-2022",
        };

        break;
      case "House Funds":
        refData["House Funds"] = {};
        entry[1].forEach((item) => {
          const county =
            item[
              "Housing Units where Householders spend at least 30% of income on housing "
            ];
          const value = [item["Column3"]];
          refData["House Funds"][county] = value;
        });
        cardData["House Funds"] = {
          card: "double",
          format: ["percent"],
          title: [""],
          headertitle:
            "Housing Units where Householders spend at least 30% of income on housing",
          subtitle: "2018-2022",
        };
        break;
      case "Child Care Facilities":
        refData["Child Care Facilities"] = {};
        entry[1].forEach((item) => {
          const county =
            item["Total number of child care facilities in North Carolina "];
          const value = [item["Column3"]];
          refData["Child Care Facilities"][county] = value;
        });
        cardData["Child Care Facilities"] = {
          card: "double",
          format: ["number"],
          title: [""],
          headertitle: "Total number of child care facilities.",
          subtitle: "2022",
        };
        break;
      case "Children in foster care":
        refData["Children in foster care"] = {};
        entry[1].forEach((item) => {
          const county =
            item["Children under the age of 18 in Foster Care in NC"];
          const value = [item["Column3"]];
          refData["Children in foster care"][county] = value;
        });
        cardData["Children in foster care"] = {
          card: "double",
          format: ["number"],
          title: [""],
          headertitle: "Children under the age of 18 in Foster Care.",
          subtitle: "2023",
        };
        break;
      case "Prenatal Care":
        refData["Prenatal Care"] = {};
        const datax = entry[1];
        datax.forEach((item, i) => {
          if (
            "Births to women receiving very late or no prenatal care in North Carolina " in
            item
          ) {
            const county =
              item[
                "Births to women receiving very late or no prenatal care in North Carolina "
              ];
            const item1 = datax[i]["Column3"];
            const item2 = datax[i + 1]["Column3"];
            let value = [item1, item2];
            refData["Prenatal Care"][county] = value;
          }
        });
        cardData["Prenatal Care"] = {
          card: "double",
          format: ["number", "percent"],
          title: ["Number of births.", "% of births."],
          headertitle:
            "Births to women receiving very late or no prenatal care.",
          subtitle: "",
        };
        break;
      case "Child abuse and neglect":
        refData["Child abuse and neglect"] = {};
        const dataz = entry[1];
        dataz.forEach((item, i) => {
          if (
            "Child Abuse and Neglect Reports Investigated and Substantiated in NC" in
            item
          ) {
            const county =
              item[
                "Child Abuse and Neglect Reports Investigated and Substantiated in NC"
              ];
            const item1 = dataz[i]["Column4"];
            const item2 = dataz[i + 1]["Column4"];
            const item3 = dataz[i + 2]["Column4"];
            const item4 = dataz[i + 3]["Column4"];
            let value = [item1, item2, item3, item4];
            refData["Child abuse and neglect"][county] = value;
          }
        });
        cardData["Child abuse and neglect"] = {
          card: "double",
          format: ["number", "number", "number", "number"],
          title: [
            "Investigated number",
            "Investigated rate per 1000",
            "Substantiated number",
            "Substantiated rate per 1000",
          ],
          headertitle:
            "Child Abuse and Neglect Reports Investigated and Substantiated.",
          subtitle: "2018",
        };
        break;
      case "Uninsured":
        refData["Uninsured"] = {};
        const datau = entry[1];
        datau.forEach((item, i) => {
          if ("Uninsured population by age group in NC " in item) {
            const county = item["Uninsured population by age group in NC "];
            const item1 = datau[i]["Column4"];
            const item2 = datau[i + 1]["Column4"];
            const item3 = datau[i + 2]["Column4"];
            const item4 = datau[i + 3]["Column4"];
            let value = [item1, item2, item3, item4];
            refData["Uninsured"][county] = value;
          }
        });
        cardData["Uninsured"] = {
          card: "double",
          format: ["number", "percent", "number", "percent"],
          title: [
            "Number of Children Under Age 19.",
            "% of Children Under Age 19",
            "Number of Adults Age 18 To 64.",
            "% of Adults Age 18 To 64",
          ],
          headertitle: "Uninsured population by age group.",
          subtitle: "2008-2021",
        };
        break;
      case "Children below 100% pov line":
        refData["Children below 100% pov line"] = {};
        const datay = entry[1];
        datay.forEach((item, i) => {
          if ("Children Below 100% Poverty in North Carolina" in item) {
            const county =
              item["Children Below 100% Poverty in North Carolina"];
            const item1 = datay[i]["Column3"];
            const item2 = datay[i + 1]["Column3"];
            let value = [item1, item2];
            refData["Children below 100% pov line"][county] = value;
          }
        });
        cardData["Children below 100% pov line"] = {
          card: "double",
          format: ["number", "percent"],
          title: ["Number of children", "% of children"],
          headertitle: "Children Below 100% Poverty.",
          subtitle: "",
        };
        break;
      case "Avg grade of schools":
        refData["Avg grade of schools"] = {};
        entry[1].forEach((item) => {
          const county = item["Average of School Performance Grades "]
            ? item["Average of School Performance Grades "].trim()
            : "";
          if (item["Column2"]) {
            const value = [item["Column2"].toUpperCase().charCodeAt(0)];
            refData["Avg grade of schools"][county] = value;
          }
        });
        cardData["Avg grade of schools"] = {
          card: "double",
          format: ["grade"],
          title: [
            "Quality K-12 ed (School Performance Grades average for county schools)",
          ],
          headertitle: "Average of School Performance Grades ",
          subtitle: "",
        };
        refData["% of schools in the county that didn’t meet growth status"] =
          {};
        entry[1].forEach((item) => {
          const county = item["Average of School Performance Grades "]
            ? item["Average of School Performance Grades "].trim()
            : "";
          const value = [item["Column3"]];

          refData["% of schools in the county that didn’t meet growth status"][
            county
          ] = value;
        });
        cardData["% of schools in the county that didn’t meet growth status"] =
          {
            card: "double",
            format: ["percent"],
            title: ["% of schools that didn’t meet growth"],
            headertitle: "Average of School Performance Grades ",
            subtitle: "",
          };
        break;

      case "Top 5 Employers":
        refData["Top 5 Employers"] = {};
        const Top5Emplyer = {};
        entry[1].forEach((item) => {
          const county = item["Area Name"].replace("County", "").trim();
          if (county in Top5Emplyer) {
            Top5Emplyer[county].push({
              Year: item["Year"],
              Month: item["Month"],
              "Company Name": item["Company Name"],
              Industry: item["Industry"],
              Class: item["Class"],
              "Employment Range": item["Employment Range"],
              Rank: item["Rank"],
            });
          } else {
            Top5Emplyer[county] = [
              {
                Year: item["Year"],
                Month: item["Month"],
                "Company Name": item["Company Name"],
                Industry: item["Industry"],
                Class: item["Class"],
                "Employment Range": item["Employment Range"],
                Rank: item["Rank"],
              },
            ];
          }
        });

        Object.entries(Top5Emplyer).forEach((item) => {
          refData["Top 5 Employers"][item[0]] = item[1];
        });

        cardData["Top 5 Employers"] = {
          card: "top5",
          title: "Top 5 Employers",
        };

        break;

      default: {
      }
    }
  });

  refData["Median Family Income "] = {};

  medianFamilyIncome
    .filter((item) => item.year === "2022")
    .forEach((val) => {
      refData["Median Family Income "][val.county] = [+val.income];
    });

  cardData["Median Family Income "] = {
    card: "double",
    format: ["money"],
    title: [""],
    headertitle:
      "Median Family Income for Families Living with their children.",
    subtitle: "2022",
  };

  refData["Homeowners"] = {};

  Object.entries(households).forEach((item, i) => {
    const ci = i % 4;
    if (ci === 0) {
      const county = item[0];
      const value = [+item[1].split(",").join("")];
      refData["Homeowners"][county] = value;
    }
  });

  cardData["Homeowners"] = {
    card: "double",
    format: ["number"],
    title: [""],
    headertitle: "Total homeowners",
    subtitle: "",
  };

  refData["Voting Facilities"] = {};
  const polling = {};
  voting.Data.forEach((item, i) => {
    const county = item["county_name"];
    if (county in polling) {
      polling[county]++;
    } else {
      polling[county] = 1;
    }
  });
  Object.entries(polling).forEach((item) => {
    const county =
      item[0].toUpperCase() === "MCDOWELL"
        ? "McDowell"
        : item[0].replace(/\w+/g, function (w) {
            return w[0].toUpperCase() + w.slice(1).toLowerCase();
          });
    refData["Voting Facilities"][county] = [item[1]];
  });
  cardData["Voting Facilities"] = {
    card: "double",
    format: ["number"],
    title: [""],
    headertitle: "Voting Facilities",
    subtitle: "",
  };

  refData["Grocery stores"] = {};
  infrastructure.Data.forEach((item, i) => {
    const county = item["Geographic Area Name"]
      .split(" County, North Carolina")
      .join("");
    const value1 =
      item["Number of firms"] === "D"
        ? "Withheld to avoid disclosing data for individual companies"
        : item["Number of firms"];
    const value2 =
      item["Number of establishments"] === "D"
        ? "Withheld to avoid disclosing data for individual companies"
        : item["Number of establishments"];

    refData["Grocery stores"][county] = [value1, value2];
  });

  cardData["Grocery stores"] = {
    card: "double",
    format: ["number"],
    title: ["Number of firms", "Number of establishments"],
    headertitle: "Grocery stores",
    subtitle: "",
  };

  const vreg = {};
  Object.entries(votingreg).forEach((item, i) => {
    const ci = i % 3;
    if (ci === 0) {
      const county =
        item[1]["County"].toUpperCase() === "MCDOWELL"
          ? "McDowell"
          : item[1]["County"].replace(/\w+/g, function (w) {
              return w[0].toUpperCase() + w.slice(1).toLowerCase();
            });
      const value = votingreg[i + 1]["County"] + votingreg[i + 1]["Extra"];
      vreg[county] = +value.split("<").join("");
    }
  });

  refData["Voting Turnout rate"] = {};
  Object.entries(votingturnout).forEach((item, i) => {
    const ci = i % 4;
    if (ci === 0) {
      const county =
        item[1]["County"].toUpperCase() === "MCDOWELL"
          ? "McDowell"
          : item[1]["County"].replace(/\w+/g, function (w) {
              return w[0].toUpperCase() + w.slice(1).toLowerCase();
            });
      const value =
        votingturnout[i + 1]["County"] + votingturnout[i + 1]["Extra"];
      const value1 = votingturnout[i + 2]["County"]
        .split("%")
        .join("")
        .split("<")
        .join("");
      const value2 = votingturnout[i + 3]["County"]
        .split("%")
        .join("")
        .split("<")
        .join("");
      const reg = vreg[county];
      refData["Voting Turnout rate"][county] = [
        reg,
        +value,
        +value1 / 100,
        +value2 / 100,
      ];
    }
  });

  cardData["Voting Turnout rate"] = {
    card: "double",
    format: ["number", "number", "percent", "percent"],
    title: ["Registered voters", "Number who voted", "Turnout", "Proportion"],
    headertitle: "Voting Turnout rate",
    subtitle: "N.C. VOTER TURNOUT STATISTICS 2020 GENERAL ELECTION",
  };

  const regions = {};
  const regionCounty = {};
  quotes.forEach((item) => {
    if (item["Region"] in regions) {
      regions[item["Region"]].push(item["Counties"]);
    } else {
      regions[item["Region"]] = [item["Counties"]];
    }
  });

  Object.entries(regions).forEach((item) => {
    regionCounty[item[0]] = Array.from(new Set(item[1]));
  });

  cardData["In which North Carolina county do you currently live?"] = {
    card: "quotes",
  };
  cardData["What is going well for your family right now?"] = {
    card: "quotes",
  };
  cardData["What are the challenges that your family is facing right now?"] = {
    card: "quotes",
  };
  cardData[
    "If there could be one change in your community that would help your family to thrive, what would you want to see?"
  ] = {
    card: "quotes",
  };

  const buildQuestion = (item, idx) => {
    let Q2 = [];
    if (item["What is going well for your family right now?"]) {
      Q2.push(item["What is going well for your family right now?"]);
    }
    if (item["FIELD3"]) {
      Q2.push(item["FIELD3"]);
    }
    if (item["FIELD4"]) {
      Q2.push(item["FIELD4"]);
    }
    let Q3 = [];
    if (item["What are the challenges that your family is facing right now?"]) {
      Q3.push(
        item["What are the challenges that your family is facing right now?"]
      );
    }
    if (item["FIELD6"]) {
      Q3.push(item["FIELD6"]);
    }
    if (item["FIELD7"]) {
      Q3.push(item["FIELD7"]);
    }
    let Q4 = [];
    if (
      item[
        "If there could be one change in your community that would help your family to thrive, what would you want to see?"
      ]
    ) {
      Q4.push(
        item[
          "If there could be one change in your community that would help your family to thrive, what would you want to see?"
        ]
      );
    }
    return {
      index: idx,
      "In which North Carolina county do you currently live?": [
        item["In which North Carolina county do you currently live?"],
      ],
      "What is going well for your family right now?": Q2,
      "What are the challenges that your family is facing right now?": Q3,
      "If there could be one change in your community that would help your family to thrive, what would you want to see?":
        Q4,
    };
  };

  const quoteData = {};
  questions
    .filter(
      (item) => item["In which North Carolina county do you currently live?"]
    )
    .forEach((item, i) => {
      const region =
        countyRegion[
          item["In which North Carolina county do you currently live?"]
        ];
      if (region in quoteData) {
        quoteData[region].push(buildQuestion(item, i));
      } else {
        buildQuestion(item);
        quoteData[region] = [buildQuestion(item, i)];
      }
    });

  //data cleanup add "No Data" where data is missing

  Object.keys(countyRegion).forEach((item) => {
    if (item in refData["Homeowners"]) {
      //do nothing
    } else {
      refData["Homeowners"][item] = ["No Data"];
    }
    if (item in refData["Grocery stores"]) {
      //do nothing
    } else {
      refData["Grocery stores"][item] = ["No Data", "No Data"];
    }
  });

  return { refData, cardData, countyRegion, quoteData };
};
