import React from "react";
import { config as StoriesConfig } from "./stories/config";

export const Stories = ({ filter }) => {
  const config = StoriesConfig(filter);

  const style = {
    fontSize: config.fontSize,
    fontWeight: config.fontWeight,
    color: config.color,
    paddingTop: config.paddingTop,
    padding: config.paddingHorizontal,
    lineHeight: `${config.lineHeight / 10}em`,
  };

  return (
    <div style={style}>
      Listen to stories from families in North Carolina with the Institute for
      Family's Shine on North Carolina Campaign, a captivating initiative in
      partnership with StoryCorps Studios. Through the Shine on NC Campaign, The
      Institute is on a mission to understand and put a spotlight on family life
      across the state through residents’ unique stories. From the comfort of
      your own safe space, you're invited to join this collection of narratives,
      where every voice is celebrated. Discover the captivating tales from North
      Carolina's families and join the narrative by visiting the Institute for
      Family website at{" "}
      <a href={"https://www.instituteforfamily.org/shine-stories"}>
        instituteforfamily.org/shine-stories. 
      </a>
    </div>
  );
};
